import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    constructor(
        private translate: TranslateService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    setLanguage(language: string) {
        if (isPlatformBrowser(this.platformId)) {
            this.translate.use(language.toLowerCase());
            document.documentElement.setAttribute(
                'dir',
                language.toLowerCase() === 'ar' ? 'rtl' : 'ltr'
            );
        }
    }

    addLanguages() {
        this.translate.addLangs(['fr', 'ar', 'en']);
        this.translate.setDefaultLang('fr');

    }

    getTranslation(): TranslateService {
        return this.translate;
    }
}
