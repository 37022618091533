import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../core/models/store-typings.model';
import {
  ClearImageUrlFromStoreAction,
  ClearPdfUrlFromStoreAction,
  deleteUploadedFilesAction,
  deleteFileAction,
  uploadImageAction,
  uploadPdfAction,
  resetImageDeletedValueAction,
} from '../store/upload.actions';
import { uploadQueries } from '../store/upload.selector';
import { UploadedFiles } from '../../../core/models/typings.models';

@Injectable({ providedIn: 'root' })
export class UploadFacade {
  constructor(private store: Store<AppState>) {}
  public fetchUploadedImage(): Observable<UploadedFiles> {
    return this.store.pipe(select(uploadQueries.uploadedImageQuery));
  }
  public uploadImage(
    imageFile: File,
    sourceFromRichText: boolean = false
  ): void {
    this.store.dispatch(uploadImageAction({ imageFile, sourceFromRichText }));
  }

  public fetchUploadedPdf(): Observable<UploadedFiles> {
    return this.store.pipe(select(uploadQueries.uploadedPdfQuery));
  }
  public uploadPdf(pdfFile: File, sourceFromRichText: boolean = false): void {
    this.store.dispatch(uploadPdfAction({ pdfFile, sourceFromRichText }));
  }
  public deleteFile(fileUrl: string) {
    this.store.dispatch(deleteFileAction({ fileUrl }));
  }
  public clearPdfUrlFromStore() {
    this.store.dispatch(ClearPdfUrlFromStoreAction());
  }
  public clearImageUrlFromStore() {
    this.store.dispatch(ClearImageUrlFromStoreAction());
  }
  public imagesDeleted() {
    return this.store.pipe(select(uploadQueries.imagesDeletedQuery));
  }
  public deleteUploadedFiles(fileUrls: string[]): void {
    return this.store.dispatch(deleteUploadedFilesAction({ fileUrls })); // Dispatch action to reset the flag
  }
  public resetImageDeletedValue(): void {
    return this.store.dispatch(resetImageDeletedValueAction()); // Dispatch action to reset the flag
  }
}
