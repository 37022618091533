import { AfterViewInit, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BlogShowcaseComponent } from '../blog/component/blog-showcase/blog-showcase.component';
import { CommonModule } from '@angular/common';
import 'intersection-observer';
import { BlogAttributes } from '../../core/models/typings.models';
import { BlogFacade } from '../blog/services/blog-facade.service';
import { Subject, takeUntil } from 'rxjs';
import { contactInfo } from '../../shared/contact-info';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [TranslateModule, BlogShowcaseComponent, CommonModule],
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements AfterViewInit {
  blogs: BlogAttributes[] = [];
  private destroy$ = new Subject<void>();
  contactInfo = contactInfo;
  contactMethods = [
    {
      email: 'marketing@aldismed.com',
      phone: '+1 234 567 890',
    },
    {
      email: 'commercial@aldismed.com',
      phone: '+1 234 567 890',
    },
    {
      email: 'direction@aldismed.com',
      phone: '+1 234 567 890',
    },
  ];

  constructor(private blogFacade: BlogFacade) {}
  ngAfterViewInit() {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.5 }
      );
      document.querySelectorAll('.page-element').forEach((element) => {
        observer.observe(element);
      });
    }

    this.loadBlogs();
  }
  navigateToForm(): void {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSdnH0cKmdjaM9WJyv_FjZ09mZnA7UOc_jswkenQLBBrzP4Wbg/viewform',
      '_blank'
    );
  }

  naviagteToReclamation(): void {
    window.open('  https://forms.gle/hGLuGW45jVFk82X59', '_blank');
  }

  loadBlogs(): void {
    this.blogFacade.loadBlogList();
    this.blogFacade
      .fetchBlogList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((blogs: BlogAttributes[]) => {
        const sortedBlogs = [...blogs].sort(
          (a, b) =>
            new Date(b.postDate).getTime() - new Date(a.postDate).getTime()
        );
        this.blogs = sortedBlogs.slice(0, 3);
      });
  }
}
