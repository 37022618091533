import {
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { EditorModule } from '@tinymce/tinymce-angular';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ProductFacade } from '../../../products/services/product-facade.service';
import {
  ProductAttributes,
  UploadedFiles,
} from '../../../../core/models/typings.models';
import { UploadFacade } from '../../../../shared/upload/services/upload-facade.service';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertsService } from '../../../../shared/alerts-component/alerts.service';

@Component({
  selector: 'app-add-product',
  standalone: true,
  imports: [
    TranslateModule,
    SidebarComponent,
    FormsModule,
    EditorModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './add-product.component.html',
  styleUrl: './add-product.component.scss',
})
export class AddProductComponent implements OnInit, OnDestroy {
  richTextFiles: string[] = [];
  isUploadingRichtextFile = false;
  productForm!: FormGroup;
  submitClicked = false;
  uploadedImages: string[] = [];
  uploadedFile = '';
  uploadedFileName = '';
  destroy$ = new Subject<void>();
  productId = '1';
  private alertsService = inject(AlertsService);

  editorInit = {
    apiKey: 'ncpv4d4cj0orbco0akyy8ykkzw87okos6548u45uz7cabrqr',
    height: 400,
    menubar: false,
    plugins: 'lists link image table textcolor colorpicker code',
    toolbar: `undo redo | bold italic underline | fontfamily | formatselect | fontsize | blocks |
      fontsizeselect fontselect | forecolor backcolor | alignleft aligncenter alignright | link |
      bullist numlist | image | table`,
    font_formats:
      'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; ...',
    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
    file_picker_types: 'file image',
    automatic_uploads: false,
    file_picker_callback: (
      callback: (url: string, meta: { alt: string }) => void
    ) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '*/*';
      input.onchange = async () => {
        const file = input.files?.[0];
        if (file) {
          this.isUploadingRichtextFile = true;
          const isImage = file.type.startsWith('image/');
          const uploadObservable = isImage
            ? this.uploadFacade.uploadImage(file, true)
            : this.uploadFacade.uploadPdf(file, true);

          const fetchObservable = isImage
            ? this.uploadFacade.fetchUploadedImage()
            : this.uploadFacade.fetchUploadedPdf();
          const clearFileObservable = isImage
            ? this.uploadFacade.clearImageUrlFromStore()
            : this.uploadFacade.clearPdfUrlFromStore();

          fetchObservable
            .pipe(takeUntil(this.destroy$))
            .subscribe((uploadedfile: UploadedFiles) => {
              if (
                uploadedfile &&
                uploadedfile.url &&
                uploadedfile.sourceFromRichText === true
              ) {
                callback(uploadedfile.url, { alt: file.name });
                this.richTextFiles.push(uploadedfile.url);
                clearFileObservable;
              }
              this.isUploadingRichtextFile = false;
            });
        }
      };
      input.click();
    },
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private productFacade: ProductFacade,
    private uploadFacade: UploadFacade,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.initilizeProductForm();
  }
  private categoryMapping: { [key: string]: string } = {
    Bandages: 'tBandages',
    Sparadraps: 'tPlasters',
    Compresses: 'tCompresses',
    Pansements: 'tDressings',
    Autres: 'tOthers',
  };
  ngOnInit(): void {
    this.handleUpload();
    this.handleClearForm();
    this.route.queryParams.subscribe((params: Params) => {
      const productId = params['id'];
      if (productId) {
        this.productId = productId;
        this.loadProductData(productId);
      }
    });
  }

  private initilizeProductForm() {
    this.productForm = new FormGroup({
      category: new FormControl('', Validators.required),
      reference: new FormControl('', Validators.required),
      isBestSeller: new FormControl(false),
      isNew: new FormControl(false),
      isSterile: new FormControl(false),
      titleFR: new FormControl('', Validators.required),
      titleEN: new FormControl('', Validators.required),
      titleAR: new FormControl('', Validators.required),
      descriptionFR: new FormControl('', Validators.required),
      descriptionEN: new FormControl('', Validators.required),
      descriptionAR: new FormControl('', Validators.required),
      descriptionGeneraleFR: new FormControl('', Validators.required),
      descriptionGeneraleEN: new FormControl('', Validators.required),
      descriptionGeneraleAR: new FormControl('', Validators.required),
      informationsTechniquesFR: new FormControl('', Validators.required),
      informationsTechniquesEN: new FormControl('', Validators.required),
      informationsTechniquesAR: new FormControl('', Validators.required),
      informationsGeneralesFR: new FormControl('', Validators.required),
      informationsGeneralesEN: new FormControl('', Validators.required),
      informationsGeneralesAR: new FormControl('', Validators.required),
    });
  }
  cancelRequest() {
    if (this.productId !== '1') {
      this.router.navigate(['/productlist']);
    } else {
      const filesToDelete = [
        ...this.uploadedImages,
        this.uploadedFile,
        ...this.richTextFiles,
      ].filter((file) => file !== '');

      if (filesToDelete.length > 0) {
        this.uploadFacade.deleteUploadedFiles(filesToDelete);
        this.uploadFacade
          .imagesDeleted()
          .pipe(takeUntil(this.destroy$))
          .subscribe((imagesDeleted) => {
            if (imagesDeleted) {
              this.resetFormValues();
            }
          });
      } else {
        this.resetFormValues();
      }
    }
  }

  handleClearForm() {
    this.productFacade
      .productAdded()
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerAdded) => {
        if (partnerAdded) {
          this.resetFormValues();
          if (this.productId !== '1') {
            this.router.navigate(['/productlist']);
          }
        }
      });
  }

  resetFormValues() {
    this.productForm.reset();
    this.productFacade.clearProductForm();
    this.uploadFacade.resetImageDeletedValue();
    this.uploadedImages = [];
    this.uploadedFile = '';
    this.uploadedFileName = '';
    this.submitClicked = false;
    this.richTextFiles = [];
  }

  private handleUpload(): void {
    this.uploadFacade
      .fetchUploadedImage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((uploadedImage: UploadedFiles) => {
        if (
          uploadedImage &&
          uploadedImage.url &&
          uploadedImage.sourceFromRichText === false
        ) {
          this.uploadedImages.push(uploadedImage.url);
          this.uploadFacade.clearImageUrlFromStore();
        }
      });

    this.uploadFacade
      .fetchUploadedPdf()
      .pipe(takeUntil(this.destroy$))
      .subscribe((uploadedfile: UploadedFiles) => {
        if (
          uploadedfile &&
          uploadedfile.url &&
          uploadedfile.sourceFromRichText === false
        ) {
          this.uploadedFile = uploadedfile.url;
          this.uploadFacade.clearPdfUrlFromStore();
        }
      });
  }

  onFileSelect(event: Event, type: string): void {
    const input = event.target as HTMLInputElement;
    if (input?.files) {
      const files = Array.from(input.files);
      files.forEach((file) => {
        if (type === 'technicalSheet') {
          this.uploadedFileName = file.name;
          this.uploadFacade.uploadPdf(file);
        } else if (type === 'photos') {
          this.uploadFacade.uploadImage(file);
        }
      });
    }
    input.value = '';
  }

  deleteUploadedItem(url: string, type: string): void {
    if (type === 'technicalSheet') {
      this.uploadedFile = '';
      this.uploadedFileName = '';
    } else if (type === 'photos') {
      this.uploadedImages = this.uploadedImages.filter(
        (image) => image !== url
      );
    }
    this.uploadFacade.deleteFile(url);
  }

  isFormValid(): boolean {
    return (
      this.productForm.valid &&
      this.uploadedImages.length > 0 &&
      this.uploadedFile !== '' &&
      !this.isUploadingRichtextFile
    );
  }

  getValidationClass(controlName: string): string {
    if (this.isInvalid(controlName)) {
      return 'is-invalid';
    } else if (this.isValid(controlName)) {
      return 'is-valid';
    }
    return '';
  }
  isInvalid(controlName: string): boolean {
    if (controlName === 'photos') {
      return this.uploadedImages.length <= 0 && this.submitClicked;
    } else if (controlName === 'technicalSheet') {
      return this.uploadedFile == '' && this.submitClicked;
    } else {
      const control = this.productForm.get(controlName);
      return control!.invalid && (control!.touched || this.submitClicked);
    }
  }

  isValid(controlName: string): boolean {
    if (controlName === 'photos') {
      return this.uploadedImages.length > 0 && this.submitClicked;
    } else if (controlName === 'technicalSheet') {
      return this.uploadedFile !== '' && this.submitClicked;
    } else {
      const control = this.productForm.get(controlName);
      return control!.valid && (control!.touched || this.submitClicked);
    }
  }

  async submitForm(event: Event) {
    event.preventDefault();
    this.submitClicked = true;

    if (!this.isFormValid()) {
      this.alertsService.showAnAlert({
        text: 'Voulez vous remplir tous les champs.',
        style: 'warning',
        duration: 3000,
      });
      return;
    }
    const selectedCategory = this.productForm.get('category')?.value || '';
    const mappedCategory = this.categoryMapping[selectedCategory];
    if (!mappedCategory) {
      console.error('Invalid category selected');
      return;
    }
    const processedDescription = await this.processEditorContent({
      FR: this.productForm.get('descriptionGeneraleFR')?.value,
      EN: this.productForm.get('descriptionGeneraleEN')?.value,
      AR: this.productForm.get('descriptionGeneraleAR')?.value,
    });

    const processedTechInfo = await this.processEditorContent({
      FR: this.productForm.get('informationsTechniquesFR')?.value,
      EN: this.productForm.get('informationsTechniquesEN')?.value,
      AR: this.productForm.get('informationsTechniquesAR')?.value,
    });

    const processedGeneralInfo = await this.processEditorContent({
      FR: this.productForm.get('informationsGeneralesFR')?.value,
      EN: this.productForm.get('informationsGeneralesEN')?.value,
      AR: this.productForm.get('informationsGeneralesAR')?.value,
    });

    const newProduct: ProductAttributes = {
      id: this.productId,
      typeID: mappedCategory,
      reference: this.productForm.get('reference')?.value || '',
      isNew: this.productForm.get('isNew')?.value || false,
      bestSeller: this.productForm.get('isBestSeller')?.value || false,
      isSterile: this.productForm.get('isSterile')?.value || false,
      imgsrc: this.uploadedImages,
      sheetserc: this.uploadedFile,
      lanAR: {
        title: this.productForm.get('titleAR')?.value || '',
        description: this.productForm.get('descriptionAR')?.value || '',
        type: '',
        des_dim: processedDescription.AR || '',
        tecInfo: processedTechInfo.AR || '',
        gnrlInfo: processedGeneralInfo.AR || '',
      },
      lanFR: {
        title: this.productForm.get('titleFR')?.value || '',
        description: this.productForm.get('descriptionFR')?.value || '',
        type: '',
        des_dim: processedDescription.FR || '',
        tecInfo: processedTechInfo.FR || '',
        gnrlInfo: processedGeneralInfo.FR || '',
      },
      lanEN: {
        title: this.productForm.get('titleEN')?.value || '',
        description: this.productForm.get('descriptionEN')?.value || '',
        type: '',
        des_dim: processedDescription.EN || '',
        tecInfo: processedTechInfo.EN || '',
        gnrlInfo: processedGeneralInfo.EN || '',
      },
    };
    if (this.productId === '1') {
      this.productFacade.addProduct(newProduct);
    } else {
      this.productFacade.updateProduct(newProduct);
    }
  }

  private async processEditorContent(content: {
    FR: string;
    EN: string;
    AR: string;
  }): Promise<{ FR: string; EN: string; AR: string }> {
    const parser = new DOMParser();
    const docFR = parser.parseFromString(content.FR, 'text/html');
    const docAR = parser.parseFromString(content.AR, 'text/html');
    const docEN = parser.parseFromString(content.EN, 'text/html');

    const imgElementsFR = Array.from(docFR.querySelectorAll('img'));
    const imgElementsAR = Array.from(docAR.querySelectorAll('img'));
    const imgElementsEN = Array.from(docEN.querySelectorAll('img'));

    const updateImageSrc = (
      img: HTMLImageElement,
      matchingImages: HTMLImageElement[]
    ) => {
      const src = img.getAttribute('src');

      if (src && !src.startsWith('data:')) {
        matchingImages.forEach((matchingImg) => {
          matchingImg.setAttribute('src', src);
        });
      }
    };

    // Update images in the French content
    for (const img of imgElementsFR) {
      const matchingImages = [
        ...imgElementsAR.filter(
          (imgAR) => imgAR.getAttribute('alt') === img.getAttribute('alt')
        ),
        ...imgElementsEN.filter(
          (imgEN) => imgEN.getAttribute('alt') === img.getAttribute('alt')
        ),
      ];
      updateImageSrc(img, matchingImages);
    }

    // Update images in the English content
    for (const img of imgElementsEN) {
      const matchingARImages = imgElementsAR.filter(
        (imgAR) => imgAR.getAttribute('alt') === img.getAttribute('alt')
      );
      updateImageSrc(img, matchingARImages);
    }

    // Add class to all table elements
    const addTableClasses = (doc: Document) => {
      const tables = Array.from(doc.querySelectorAll('table'));
      tables.forEach((table) => {
        table.classList.add('table', 'table-bordered');
      });
    };

    addTableClasses(docFR);
    addTableClasses(docAR);
    addTableClasses(docEN);

    return {
      FR: docFR.body.innerHTML,
      AR: docAR.body.innerHTML,
      EN: docEN.body.innerHTML,
    };
  }

  private loadProductData(productId: string): void {
    this.productFacade.loadSelectedProduct(productId);
    this.productFacade
      .getProductDetail()
      .pipe(takeUntil(this.destroy$))
      .subscribe((product) => {
        if (product) {
          const category = Object.keys(this.categoryMapping).find(
            (key) => this.categoryMapping[key] === product.typeID
          );

          this.productForm.patchValue({
            category: category || '',
            reference: product.reference,
            isBestSeller: product.bestSeller,
            isNew: product.isNew,
            isSterile: product.isSterile,
            titleFR: product.lanFR.title,
            titleEN: product.lanEN.title,
            titleAR: product.lanAR.title,
            descriptionFR: product.lanFR.description,
            descriptionEN: product.lanEN.description,
            descriptionAR: product.lanAR.description,
            descriptionGeneraleFR: product.lanFR.des_dim,
            descriptionGeneraleEN: product.lanEN.des_dim,
            descriptionGeneraleAR: product.lanAR.des_dim,
            informationsTechniquesFR: product.lanFR.tecInfo,
            informationsTechniquesEN: product.lanEN.tecInfo,
            informationsTechniquesAR: product.lanAR.tecInfo,
            informationsGeneralesFR: product.lanFR.gnrlInfo,
            informationsGeneralesEN: product.lanEN.gnrlInfo,
            informationsGeneralesAR: product.lanAR.gnrlInfo,
          });

          this.uploadedImages = product.imgsrc || [];
          this.uploadedFile = product.sheetserc || '';

          this.uploadedFileName = product.sheetserc
            ? product.sheetserc.replace('https://aldismed.com/', '')
            : '';
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
