import { createSelector } from '@ngrx/store';
import { AppState } from '../../../core/models/store-typings.model';

const blogPage = (state: AppState) => state.blogPage;

const blogListQuery = createSelector(blogPage, (blog) => blog.bloglist);
const blogDetailsQuery = createSelector(blogPage, (blog) => blog.blogDetails);
const blogAddedQuery = createSelector(blogPage, (blog) => blog.blogAdded);

export const blogQueries = {
  blogListQuery,
  blogDetailsQuery,
  blogAddedQuery,
};
