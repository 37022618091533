import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  Inject,
  PLATFORM_ID,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BlogShowcaseComponent } from '../blog/component/blog-showcase/blog-showcase.component';
import { ImageSliderComponent } from '../image-slider/image-slider.component';
import { ProductCarouselComponent } from '../product-carousel/product-carousel.component';
import { RouterModule } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import 'intersection-observer';
import {
  BlogAttributes,
  ProductAttributes,
  ProductCarousel,
} from '../../core/models/typings.models';
import { Subject, takeUntil } from 'rxjs';
import { BlogFacade } from '../blog/services/blog-facade.service';
import { ProductFacade } from '../products/services/product-facade.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    TranslateModule,
    BlogShowcaseComponent,
    ImageSliderComponent,
    ProductCarouselComponent,
    CommonModule,
    RouterModule,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  videoUrl: string = 'https://www.youtube.com/embed/GCumDPi3JlA';
  sanitizedUrl: SafeResourceUrl;
  isPlaying: boolean = false;
  isBrowser: boolean;
  @ViewChild('customVideo', { static: true }) customVideo!: ElementRef;
  @ViewChild('playPauseBtn', { static: true }) playPauseBtn!: ElementRef;
  @ViewChildren('serviceCard') serviceCards!: QueryList<ElementRef>;
  blogs: BlogAttributes[] = [];
  private destroy$ = new Subject<void>();

  productsHeading: string = 'products_heading';
  productsDescription: string = 'products_description';

  categoriesHeading: string = 'category_heading';
  categoriesDescription: string = 'category_description';

  // Data for carousel and other products
  slides: any[] = [
    {
      url: '/assets/images/DSC03088.jpg',
      title: 'First slide',
      description: 'This is the first slide',
    },
    {
      url: '/assets/images/DSC03515.jpg',
      title: 'Second slide',
      description: 'This is the second slide',
    },
    {
      url: '/assets/images/DSC03137.jpg',
      title: 'Third slide',
      description: 'This is the third slide',
    },
    {
      url: '/assets/images/DSC03389.jpg',
      title: 'Fourth slide',
      description: 'This is the fourth slide',
    },
  ];

  newProducts: ProductAttributes[] = [];

  productCategories: ProductCarousel[] = [
    {
      image: 'assets/images/bandage.png',
      category: 'Bandages',
      title: 'Bandages',
    },
    {
      image: 'assets/images/sparadraps.png',
      category: 'spa_dev',
      title: 'spa_dev',
    },
    {
      image: 'assets/images/pensments1.png',
      category: 'pan_pat',
      title: 'pan_pat',
    },
    {
      image: 'assets/images/compresses.jpg',
      category: 'Compresses',
      title: 'Compresses',
    },
    {
      image: 'assets/images/sparadraps.png',
      category: 'con_chg',
      title: 'con_chg',
    },
  ];

  isLargeScreen = true;
  currentNewProductIndex = 0;
  currentCategoryIndex = 0;

  constructor(
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private sanitizer: DomSanitizer,
    private blogFacade: BlogFacade,
    private productFacade: ProductFacade
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.videoUrl
    );
  }

  ngOnInit() {
    this.loadBlogs();
    this.loadProducts();
  }

  navigateToSubcontracting() {
    this.router.navigate(['/subcontracting']);
  }

  nextProduct(type: string) {
    const itemsPerPage = 1;

    if (type === 'new') {
      if (
        this.currentNewProductIndex + itemsPerPage <
        this.newProducts.length
      ) {
        this.currentNewProductIndex += itemsPerPage;
      }
    } else if (type === 'category') {
      if (
        this.currentCategoryIndex + itemsPerPage <
        this.productCategories.length
      ) {
        this.currentCategoryIndex += itemsPerPage;
      }
    }
  }

  prevProduct(type: string) {
    const itemsPerPage = 1;

    if (type === 'new') {
      if (this.currentNewProductIndex - itemsPerPage >= 0) {
        this.currentNewProductIndex -= itemsPerPage;
      }
    } else if (type === 'category') {
      if (this.currentCategoryIndex - itemsPerPage >= 0) {
        this.currentCategoryIndex -= itemsPerPage;
      }
    }
  }
  ngAfterViewInit() {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.5 }
      );
      document.querySelectorAll('.page-element').forEach((element) => {
        observer.observe(element);
      });
    }
  }
  loadBlogs(): void {
    this.blogFacade.loadBlogList();
    this.blogFacade
      .fetchBlogList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((blogs: BlogAttributes[]) => {
        this.blogs = blogs.slice(0, 3);
      });
  }
  loadProducts(): void {
    this.productFacade.loadProductList();
    this.productFacade
      .fetchProductList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((products: ProductAttributes[]) => {
        this.newProducts = products.slice(0, 6);
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
