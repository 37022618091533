<div class="fade-in-left description">
  <div class="max-width-container">
    <div class="pb-5">
      <div
        class="container-fluid row row-cols-1 row-cols-md-2 align-items-center g-4 w-100 m-0"
      >
        <div class="col description page-element mx-0 mb-3">
          <div class="row custom-badge text-dark mb-3 mx-0">
            <div class="col-auto badge-icon">
              <img
                src="assets/images/top.png"
                alt="Medical supplies"
                class="img-fluid rounded-circle top-image"
              />
            </div>
            <div class="col badge-description">
              <span class="top-text">{{ "top_text" | translate }}</span>
            </div>
          </div>

          <div>
            <h1 class="display-3 fw-bold">{{ "home_ttl1" | translate }}</h1>
            <p>
              {{ "home_txt1" | translate }}
            </p>
            <p>
              {{ "home_txt2" | translate }}
            </p>
          </div>
          <div
            class="row row-cols-auto g-3 d-flex justify-content-center justify-content-md-start m-0"
          >
            <div class="col">
              <a routerLink="/products" class="btn btn-primary">{{
                "our_product" | translate
              }}</a>
            </div>
            <div class="col">
              <a routerLink="/about" class="btn btn-outline-secondary">{{
                "abouts" | translate
              }}</a>
            </div>
          </div>
        </div>

        <div
          class="row position-relative text-center d-flex justify-content-center justify-content-md-end page-element mx-0"
        >
          <app-image-slider
            [slides]="slides"
            [autoPlay]="true"
            [autoPlaySpeed]="4000"
          ></app-image-slider>

          <div class="blockquote-container">
            <blockquote class="custom-blockquote text-start">
              <p>“{{ "home_card_text" | translate }}”</p>
              <div class="row d-flex justify-content-between m-0">
                <footer class="col-auto blockquote-footer">
                  {{ "pdg" | translate }}<br />
                  <cite title="Source Title">{{
                    "pdg_title" | translate
                  }}</cite>
                </footer>
                <div class="col-auto footer-quote d-flex align-items-center">
                  <i class="bi bi-quote"></i>
                </div>
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card shadow-sm max-width-container my-4">
    <div class="card-header col-12 page-element">
      <div
        class="row row-cols-1 row-cols-md-auto g-4 text-center mb-4 mx-0 d-flex justify-content-center p-0 max-width-container"
      >
        <div class="col d-flex align-items-center justify-content-center">
          <img
            src="assets/images/iso13485.png"
            alt="ISO 13485"
            class="img-fluid mx-2"
          />
        </div>
        <div class="col d-flex align-items-center justify-content-center">
          <img
            src="assets/images/GMP.png"
            alt="GMP Quality"
            class="img-fluid mx-2"
          />
        </div>
        <div class="col d-flex align-items-center justify-content-center">
          <img
            src="assets/images/iso9001.png"
            alt="ISO 9001"
            class="img-fluid mx-2"
          />
        </div>
      </div>
    </div>
    <div class="container-fluid p-0 page-element">
      <div class="video-overlay position-relative">
        <div class="overlay-heading text-center text-white">
          <h2 class="responsive-heading font-weight-bold">
            {{ "vidio_txt1" | translate }}<br />{{ "vidio_txt2" | translate }}
          </h2>
        </div>

        <div #customVideo class="video-container h-100 w-100">
          <iframe
            class="video-element w-100 h-100"
            [src]="sanitizedUrl"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="newProducts.length > 2"
    class="container-fluid max-width-container"
  >
    <app-product-carousel
      [newProducts]="newProducts"
      [isLargeScreen]="isLargeScreen"
      [currentNewProductIndex]="currentNewProductIndex"
      [heading]="productsHeading"
      [description]="productsDescription"
      (prevProduct)="prevProduct($event)"
      (nextProduct)="nextProduct($event)"
    ></app-product-carousel>
  </div>

  <div class="container-fluid max-width-container">
    <h1 class="mb-4">{{ "srv__title" | translate }}</h1>
    <div class="row row-cols-1 row-cols-md-2 g-4">
      <div class="col-12 col-md-4 d-flex">
        <div class="service-card large-card text-white">
          <div class="icon-container">
            <div class="icon d-flex align-items-start justify-content-start">
              <img
                src="assets/icons/subcontracting.svg"
                alt="Subcontracting"
                width="60"
              />
            </div>
          </div>
          <div class="card-body-sub mt-4">
            <h5>{{ "cst_card_title" | translate }}</h5>
            <p>{{ "cst_card_txt" | translate }}</p>
          </div>
          <div class="col d-flex justify-content-end align-items-end w-100">
            <button
              class="btn btn-light text-primary mt-4"
              (click)="navigateToSubcontracting()"
            >
              {{ "voir_plus" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-7 align-items-stretch">
        <div class="row row-cols-1 row-cols-md-2 g-4">
          <div class="col d-flex page-element">
            <div class="service-card small-card bg-light w-100">
              <div class="icon-container-small">
                <div
                  class="icon d-flex align-items-center justify-content-center"
                >
                  <img
                    src="assets/icons/production.svg"
                    alt="Production"
                    width="60"
                  />
                </div>
              </div>
              <div class="card-body mt-4">
                <h5>{{ "prd_card_title" | translate }}</h5>
                <p>{{ "prd_card_txt1" | translate }}</p>
                <p>{{ "prd_card_txt2" | translate }}</p>
              </div>
            </div>
          </div>

          <div class="col d-flex page-element">
            <div class="service-card small-card bg-light w-100">
              <div class="icon-container-small">
                <div
                  class="icon d-flex align-items-center justify-content-center"
                >
                  <img
                    src="assets/icons/distribution.svg"
                    alt="Distribution"
                    width="60"
                  />
                </div>
              </div>
              <div class="card-body mt-4">
                <h5>{{ "dsc_card_title" | translate }}</h5>
                <p>{{ "dsc_card_txt" | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="container-fluid-aboutUs text-center text-md-start my-5 page-element d-flex justify-content-center"
  >
    <div
      class="row justify-content-start align-items-center max-width-container mx-0"
    >
      <div class="col-lg-6 col-md-12 text-white mb-4 mb-lg-0 ms-md-5">
        <h2
          class="d-flex justify-content-center justify-content-md-start display-6 pt-5"
        >
          {{ "abouts" | translate }}
        </h2>
        <p class="lead">
          {{ "Historique" | translate }}
        </p>
        <div
          class="d-flex flex-column flex-md-row justify-content-start align-items-center my-4 mx-0"
        >
          <div
            class="about-us-tags d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <span
              class="custom-span d-flex align-items-center justify-content-center"
            >
              <img
                src="assets/icons/valid.svg"
                alt="valid"
                width="20"
                class="me-2"
              />
              {{ "exp__time" | translate }}
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <span
              class="custom-span d-flex align-items-center justify-content-center"
            >
              <img
                src="assets/icons/valid.svg"
                alt="valid"
                width="20"
                class="me-2"
              />
              {{ "prd_nbr" | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-8 col-sm-12 text-center">
        <img
          src="assets/images/DSC03515.jpg"
          alt="Medical Mask and Syringe"
          class="img-fluid rounded img-extend-aboutUs"
        />
      </div>
    </div>
  </div>
  <div
    class="container-fluid max-width-container my-5 page-element max-width-container"
  >
    <app-product-carousel
      [categories]="productCategories"
      [isLargeScreen]="isLargeScreen"
      [currentNewProductIndex]="currentCategoryIndex"
      [heading]="categoriesHeading"
      [description]="categoriesDescription"
      (prevProduct)="prevProduct('category')"
      (nextProduct)="nextProduct('category')"
    ></app-product-carousel>
  </div>
</div>
<div *ngIf="blogs.length >= 3" class="container-fluid max-width-container">
  <div><app-blog-showcase [blogs]="blogs.slice(-3)"></app-blog-showcase></div>
</div>
