import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProductAttributes } from '../../../../../core/models/typings.models';
import { ProductFacade } from '../../../../products/services/product-facade.service';

@Component({
  selector: 'app-product-list-item',
  standalone: true,
  imports: [],
  templateUrl: './product-list-item.component.html',
  styleUrl: './product-list-item.component.scss'
})
export class ProductListItemComponent {
  @Input() product: ProductAttributes = {} as ProductAttributes;

  constructor(private router: Router, private productFacade: ProductFacade,
  ) { }
  navigateToEditProduct() {
    this.router.navigate(['/addproduct'], { queryParams: { id: this.product.id } });
  }

  async deleteProduct(): Promise<void> {
    if (confirm('Etes-vous sûr de vouloir supprimer ce produit ?')) {
      try {
        await this.productFacade.deleteProduct(this.product.id);
        this.router.navigate(['/productlist']);
      } catch (error) {
        console.error('Erreur lors de la suppression du produit :', error);
      }
    }
  }
}
