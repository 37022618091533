<div class="list-group-item d-flex justify-content-between align-items-center">
    <div>
        <h5 class="mb-0">{{ product.lanFR.title}}</h5>
        <small class="text-muted">
            <span class="me-4">{{ product.reference}}</span>
            <span class="me-4">{{ product.lanFR.type}}</span>
        </small>
    </div>
    <div class="btn-group">
        <button class="btn btn-primary btn-bg me-2" style="background-color: #008080; color: white; border: 0px;"
            (click)="navigateToEditProduct()">
            Modifier <i class="bi bi-pencil-square"></i>
        </button>
        <button class="btn btn-danger btn-bg" (click)="deleteProduct()">
            Supprimer <i class="bi bi-trash"></i>
        </button>
    </div>
</div>