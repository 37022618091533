import { createAction, props } from '@ngrx/store';

// Upload Image Actions
export const uploadImageAction = createAction(
  '[Product] Upload Image',
  props<{ imageFile: File; sourceFromRichText: boolean }>()
);

export const uploadImageSuccessAction = createAction(
  '[Product] Upload Image Success',
  props<{ imageUrl: string; sourceFromRichText: boolean }>()
);

export const ClearImageUrlFromStoreAction = createAction(
  '[Product] Clear Image Url From Store'
);

export const uploadPdfAction = createAction(
  '[Product] Upload Pdf',
  props<{ pdfFile: File; sourceFromRichText: boolean }>()
);

export const uploadPdfSuccessAction = createAction(
  '[Product] Upload Pdf Success',
  props<{ pdfUrl: string; sourceFromRichText: boolean }>()
);

export const ClearPdfUrlFromStoreAction = createAction(
  '[Product] Clear Pdf Url From Store'
);
export const deleteUploadedFilesAction = createAction(
  '[Product] Clear Uploaded Images and Files From FTP',
  props<{ fileUrls: string[] }>()
);
export const UploadedFilesDeletedSuccessfulyAction = createAction(
  '[Product] Uploaded Images and Files Deleted From FTP'
);
export const resetImageDeletedValueAction = createAction(
  '[Product] Reset Image Deleted Value'
);
export const UploadedFilesDeletedFailureAction = createAction(
  '[Product] Uploaded Images and Files Failed to Delete From FTP',
  props<{ error: string }>()
);

export const deleteFileAction = createAction(
  '[Product] Delete File',
  props<{ fileUrl: string }>()
);

export const deleteFileSuccessAction = createAction(
  '[Product] Delete File Success',
  props<{ fileUrl: string }>()
);

export const deleteFileFailureAction = createAction(
  '[Product] Delete File Failure',
  props<{ error: string }>()
);
