<div class="container-fluid py-5">
  <div class="row row-cols-1 row-cols-md-2 g-2 d-flex justify-content-evenly">
    <div class="col-12 col-md-3 filter-section">
      <h5>{{ "filter" | translate }}</h5>
      <hr />
      <h6>{{ "type" | translate }}</h6>
      <div class="form-check p-0" *ngFor="let filter of filters">
        <div
          class="row row-cols-2 justify-content-center align-items-center g-2"
        >
          <div class="col-8 justify-content-start">
            <label class="form-check-label" [for]="filter.label">
              {{ filter.label }} ({{ filter.count }})
            </label>
          </div>
          <div class="col-4 d-flex justify-content-center">
            <input
              class="form-check-input"
              type="checkbox"
              [id]="filter.label"
              [(ngModel)]="filter.selected"
              (change)="onFilterChange()"
            />
          </div>
        </div>
      </div>
      <hr />
    </div>

    <div class="col-12 col-md-9">
      <div class="row mx-0">
        <div class="col-12 products-count mb-4">
          <span class="text-secondary"
            >{{ selectedCount }} {{ "Products-Found" | translate }}</span
          >
        </div>

        <div
          class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-3 d-flex justify-content-center"
          *ngFor="
            let product of filteredProducts
              | paginate
                : { itemsPerPage: itemsPerPage, currentPage: currentPage }
          "
        >
          <app-product-item
            class="w-100 product-item-wrapper me-3"
            [product]="product"
          ></app-product-item>
        </div>

        <div class="pagination-container">
          <pagination-controls
            (pageChange)="onPageChange($event)"
            [autoHide]="true"
            [directionLinks]="true"
            previousLabel=""
            nextLabel=""
          >
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
