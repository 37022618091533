import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BlogItemComponent } from './component/blog-item/blog-item.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlogAttributes } from '../../core/models/typings.models';
import { BlogFacade } from './services/blog-facade.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    BlogItemComponent,
    NgxPaginationModule,
  ],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BlogComponent implements OnInit, OnDestroy {
  blogs: BlogAttributes[] = [];
  destroy$ = new Subject<void>();

  selectedCount = 0;

  itemsPerPage = 9;
  currentPage = 1;

  items = Array.from({ length: this.selectedCount }, (_, i) => `Item ${i + 1}`);

  constructor(private blogFacade: BlogFacade) {}

  ngOnInit(): void {
    this.getBlogList();
  }
  getBlogList() {
    this.blogFacade.loadBlogList();
    this.fetchBlogs();
  }

  fetchBlogs() {
    this.blogFacade
      .fetchBlogList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((blogsList: BlogAttributes[]) => {
        this.blogs = blogsList;
        this.selectedCount = blogsList.length;
      });
  }

  onPageChange(page: number): void {
    this.currentPage = page;

    const element = document.getElementById('blog-list');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
