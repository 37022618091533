import { Action, createReducer, on } from '@ngrx/store';
import { IProductPageState } from '../../../core/models/store-typings.model';
import {
  clearProductFormAction,
  productAddedAction,
  productDeletedAction,
  productDetailsFetchedAction,
  productListFetchedAction,
  productTypeListFetchedAction,
  productUpdatedAction,
} from './product.actions';

export const productInitialState: IProductPageState = {
  ProductDetails: {
    id: '',
    bestSeller: false,
    isNew: false,
    isSterile: false,
    imgsrc: [],
    sheetserc: '',
    lanAR: {
      description: '',
      title: '',
      type: '',
      des_dim: '',
      gnrlInfo: '',
      tecInfo: '',
    },
    lanEN: {
      description: '',
      title: '',
      type: '',
      des_dim: '',
      gnrlInfo: '',
      tecInfo: '',
    },
    lanFR: {
      description: '',
      title: '',
      type: '',
      des_dim: '',
      gnrlInfo: '',
      tecInfo: '',
    },
    reference: '',
    typeID: '',
  },
  ProductList: [],
  TypeList: [],
  productAdded: false,
};
const ProductPageReducer = createReducer(
  productInitialState,
  on(productListFetchedAction, (state, { payload }) => ({
    ...state,
    ProductList: payload,
  })),
  on(productDetailsFetchedAction, (state, action) => ({
    ...state,
    ProductDetails: action.payload,
  })),
  on(productTypeListFetchedAction, (state, { payload }) => ({
    ...state,
    TypeList: payload,
  })),
  on(productAddedAction, (state, { payload }) => ({
    ...state,
    ProductList: [...state.ProductList, payload],
    productAdded: true,
  })),
  on(clearProductFormAction, (state) => ({
    ...state,
    productAdded: false,
  })),
  on(productUpdatedAction, (state, { payload }) => ({
    ...state,
    ProductList: state.ProductList.map((product) =>
      product.id === payload.id ? payload : product
    ),
    productAdded: true,
  })),
  on(productDeletedAction, (state, { payload }) => ({
    ...state,
    ProductList: state.ProductList.filter((product) => product.id !== payload),
  }))
);

export function productPageRootReducer(
  state: IProductPageState | undefined,
  action: Action
) {
  return ProductPageReducer(state, action);
}
