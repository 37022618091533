<div class="max-width-container">
  <div class="mb-4 row row-cols-1 row-cols-lg-2 g-4 mx-0">
    <div class="col d-flex justify-content-start px-0">
      <h1>Our Blog</h1>
    </div>
    <div class="col d-flex justify-content-start justify-content-lg-end px-0">
      <button class="btn btn-primary px-5" (click)="navigateToBlogs()">
        View All
      </button>
    </div>
  </div>

  <div *ngIf="blogs && blogs.length > 0; else noBlogs" class="row g-4">
    <ng-container *ngFor="let blog of blogs.slice(0, 3)">
      <div class="col-12 col-md-4">
        <div class="card h-100 blog-item-wrapper">
          <app-blog-item [blog]="blog"></app-blog-item>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #noBlogs>
    <p>No blogs available to display.</p>
  </ng-template>
</div>
