<div class="d-flex vh-100 force-ltr">
    <app-sidebar class="sidebar"></app-sidebar>

    <div class="flex-grow-1 p-4 main-content">
        <div class="card p-4">
            <h2 class="mb-4">Blog List</h2>

            <div id="blog-list">
                <div *ngIf="totalPages === 0" class="text-center">
                    <p>Aucun blog disponible.</p>
                </div>

                <div *ngFor="let blog of blogs | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                    <div class="card mb-3 p-3">
                        <app-blog-list-item [blog]="blog"></app-blog-list-item>
                    </div>
                </div>

                <div class="pagination-container" *ngIf="totalPages > 0">
                    <button class="pagination-arrow" [attr.aria-label]="'Previous page'"
                        (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">
                        <i class="bi bi-chevron-left"></i>
                    </button>
                    <ng-container *ngFor="let page of pages; let i = index">
                        <div class="pagination-dot" [class.active]="currentPage === page" (click)="onPageChange(page)">
                        </div>
                    </ng-container>

                    <button class="pagination-arrow" aria-label="Next page" (click)="onPageChange(currentPage + 1)"
                        [disabled]="currentPage === totalPages || totalPages === 0">
                        <i class="bi bi-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>