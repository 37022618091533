<div class="container mt-4">
  <div class="row row-cols-1 row-cols-md-2 g-4">
    <!-- Product Image Section -->
    <div class="col">
      <div class="product-image-container">
        <button
          class="nav-button prev"
          (click)="prevImage()"
          [disabled]="isPrevDisabled()"
        >
          &#10094;
        </button>
        <img
          #productImage
          class="image-zoom"
          [src]="currentImage"
          alt="Product Image"
        />
        <button
          class="nav-button next"
          (click)="nextImage()"
          [disabled]="isNextDisabled()"
        >
          &#10095;
        </button>
      </div>
    </div>

    <!-- Product Overview Section -->
    <div class="col product-overview">
      <h1 class="mb-4 product-title">
        {{ title }}
      </h1>
      <div class="mb-4">
        <a
          [href]="product.sheetserc"
          target="_blank"
          class="btn btn-primary btn-sm"
          >{{ "tech-button" | translate }}</a
        >
        <p class="mt-4">
          <strong>{{ "reference" | translate }} {{ product.reference }}</strong>
        </p>
        <p>
          <strong>{{ "categories" | translate }} {{ type }}</strong>
        </p>
      </div>
      <p>
        {{ description | slice:0:400 }}{{ description.length >400 ? '...' : '' }}
      </p>      
    </div>
  </div>

  <div class="mt-4">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="description-tab"
          data-bs-toggle="tab"
          href="#description"
          role="tab"
        >
          {{ "description-prd" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="additional-info-tab"
          data-bs-toggle="tab"
          href="#additional-info"
          role="tab"
        >
          {{ "additional-info" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="general-info-tab"
          data-bs-toggle="tab"
          href="#general-info"
          role="tab"
        >
          {{ "general-info" | translate }}</a
        >
      </li>
    </ul>

    <div class="tab-content mt-3 ms-3" id="myTabContent">
      <div
        #dimDic
        class="tab-pane fade show active content-section"
        id="description"
        role="tabpanel"
      ></div>

      <div
        #additionalInfo
        class="tab-pane fade content-section"
        id="additional-info"
        role="tabpanel"
      ></div>

      <div
        #generalInfo
        class="tab-pane fade content-section"
        id="general-info"
        role="tabpanel"
      ></div>
    </div>
  </div>

  <div class="text-center mt-4 mb-4">
    <button class="btn-outline-custom" (click)="navigateToProducts()">
      <span>&larr;</span> {{ "back-prd-button" | translate }}
    </button>
  </div>
</div>
