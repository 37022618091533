import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../../core/services/authService/auth.service';
import { LoginFacade } from '../../../login/service/login-facade.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule, RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
    private loginFacade: LoginFacade
  ) {}

  onLogout() {
    this.loginFacade.logOut();
  }
}
