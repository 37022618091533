import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IAlert } from '../../core/models/typings.models';

@Injectable({ providedIn: 'root' })
export class AlertsService {
  private _alertsFeed$ = new Subject<IAlert>();
  public get alertsFeed$() {
    return this._alertsFeed$.asObservable();
  }

  showAnAlert(alertData: IAlert) {
    this._alertsFeed$.next(alertData);
  }
}
