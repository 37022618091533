<div class="item_container">
  <img
    src="{{ blog.coverSrc }}"
    class="card card-img-top blog-cover"
    alt="Blog Cover"
  />
  <div class="card-body">
    <div class="d-flex align-items-center justify-content-between mt-2">
      <h6 class="card-subtitle mb-1 text-muted">{{ blog.postDate }}</h6>
      <div class="d-flex align-items-center">
        <i class="bi bi-geo-alt-fill me-2 mb-1"></i>
        <h6 class="card-subtitle mb-1 text-muted">{{ location }}</h6>
      </div>
    </div>

    <h4 class="card-title truncate-text">{{ title }}</h4>
    <p class="card-text truncate-multiline">
      <small class="text-muted">{{ description }}</small>
    </p>
    <a
      class="card-link small"
      (click)="navigateToDetail()"
      >{{ "read_more" | translate }}></a
    >
  </div>
</div>
