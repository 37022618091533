import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ProductListItemComponent } from "./product-list-item/product-list-item.component";
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProductAttributes } from '../../../../core/models/typings.models';
import { ProductFacade } from '../../../products/services/product-facade.service';
import { catchError, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [TranslateModule, SidebarComponent, ProductListItemComponent, NgxPaginationModule, CommonModule],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss'
})
export class ProductListComponent {
  private destroy$ = new Subject<void>();

  products: ProductAttributes[] = [];
  selectedCount = 0;
  itemsPerPage = 6;
  currentPage = 1;
  totalPages = 0;
  pages: number[] = [];

  constructor(private productFacade: ProductFacade) { }
  ngOnInit(): void {
    this.loadProducts();
  }

  private loadProducts(): void {
    this.productFacade.loadProductList();
    this.fetchProducts();
  }

  private fetchProducts(): void {
    this.productFacade
      .fetchProductList()
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          console.error('Failed to fetch blog list:', error);
          return [];
        })
      )
      .subscribe((productsList: ProductAttributes[]) => {
        this.products = productsList;
        this.selectedCount = productsList.length;
        this.totalPages = this.selectedCount > 0 ? Math.ceil(this.selectedCount / this.itemsPerPage) : 0;
        this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
      });
  }

  onPageChange(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      document.getElementById('product-list')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
