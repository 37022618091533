import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { LoginFacade } from './service/login-facade.service';
import * as CryptoJS from 'crypto-js'; // Import CryptoJS for hashing

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  public loginFailMsg$: Observable<string | null> = new Observable();
  public displayPassword = false;

  constructor(private facadeLogin: LoginFacade, private fb: FormBuilder) {
    this.loginFailMsg$ = this.facadeLogin.errorLoginMsg;
  }

  ngOnInit(): void {
    this.initializeLoginForm();
    this.facadeLogin.checkAlreadyLoggedIn();
  }

  private initializeLoginForm() {
    this.loginForm = this.fb.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }

  // Method to handle form submission
  onLogin(): void {
    if (this.loginForm.valid) {
      const hashedPassword = this.hashData(this.passwordControl.value);
      this.facadeLogin.tryLogingUser({
        username: this.usernameControl.value,
        password: hashedPassword,
      });
    }
  }

  private hashData(data: string): string {
    return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex); // Hash the data using SHA256
  }

  public get usernameControl() {
    return this.loginForm.get('username') as FormControl;
  }

  public get passwordControl() {
    return this.loginForm.get('password') as FormControl;
  }
}
