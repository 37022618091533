import { createSelector } from '@ngrx/store';
import { AppState } from '../../../core/models/store-typings.model';
const uploadPage = (state: AppState) => state.uploadPage;

export const uploadedImageQuery = createSelector(
  uploadPage,
  (upload) => upload.UploadedImage
);
export const uploadedPdfQuery = createSelector(
  uploadPage,
  (upload) => upload.UploadedPdf
);
export const imagesDeletedQuery = createSelector(
  uploadPage,
  (upload) => upload.imagesDeleted
);
export const uploadQueries = {
  uploadedImageQuery,
  uploadedPdfQuery,
  imagesDeletedQuery,
};
