import { Component } from '@angular/core';
import { SpinnerService } from './SpinnerService';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
  spinnerVisible$: Observable<boolean>;

  constructor(private spinnerService: SpinnerService) {
    this.spinnerVisible$ = this.spinnerService.getSpinnerState();
  }
}
