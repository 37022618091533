import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { BlogAttributes } from '../../../core/models/typings.models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlogService {
  constructor(private apiService: ApiService) { }

  getBlogList(): Observable<BlogAttributes[]> {
    const url = `/blog`;
    return this.apiService.get<BlogAttributes[]>(url);
  }

  getBlogDetails(id: string): Observable<BlogAttributes> {
    const url = `/blog/${id}`;
    return this.apiService.get<BlogAttributes>(url);
  }

  addBlogList(Blog: BlogAttributes): Observable<BlogAttributes> {
    const url = `/blog`;
    return this.apiService.post<BlogAttributes>(url, Blog);
  }

  deleteBlogList(id: string): Observable<boolean> {
    const url = `/blog/${id}`;
    return this.apiService.delete<boolean>(url);
  }


  updateBlogList(Blog: BlogAttributes): Observable<BlogAttributes> {
    const url = `/blog/${Blog.id}`;
    return this.apiService.patch<BlogAttributes>(url, Blog);
  }
}
