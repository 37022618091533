import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    const token = this.authService.getToken();

    if (token) {
      try {
        const decodedToken: any = jwtDecode(token);
        if (decodedToken.role === 'admin') {
          return true;
        }
      } catch (err) {
        console.error('Invalid token');
      }
    }

    this.router.navigate(['/login']);
    return false;
  }
}
