import { createAction, props } from '@ngrx/store';
import {
  ILoginCredentials,
  SignInResponse,
} from '../../../core/models/typings.models';

export const TryLoginUserAction = createAction(
  '[Login Page] [UI] Try Login User ',
  props<{ payload: ILoginCredentials }>()
);

export const routeIfAlreadyLoggedAction = createAction(
  '[Login Page] [UI] check If User is logged'
);

export const logInSuccessAction = createAction(
  '[Login Page] [UI] Login Success ',
  props<{ payload: SignInResponse }>()
);

export const LogInFailureAction = createAction(
  '[Login Page] [UI] Login Failure ',
  props<{ payload: string }>()
);

export const logOutAction = createAction(
  '[Login Page] [STATE] [UI] Logout user'
);
