<div class="fade-in-left container-fluid max-width-container py-5 bg-white">
  <div class="page-element">
    <h1 class="display-4 text-primary fw-bold">
      {{ "Subcontracting" | translate }}
      <span class="smaller-text">{{ "Subcontracting1" | translate }}</span>
    </h1>
    <p class="lead mt-4">{{ "Subcontracting_txt1" | translate }}</p>
  </div>
  <div class="py-5 page-element">
    <div class="row">
      <div class="col-md-12">
        <img
          src="assets/images/subcontracting.png"
          alt="Signing Contract"
          class="img-fluid rounded w-100"
        />
      </div>
    </div>
  </div>
  <div class="py-5 bg-white page-element">
    <div class="">
      <h2>{{ "noter_lab" | translate }}</h2>
      <p class="mt-4">
        {{ "dep1" | translate }}
      </p>
      <p class="mb-5">
        {{ "dep2" | translate }}
      </p>
      <h2 class="mt-5">{{ "cont_qual" | translate }}</h2>
      <p class="mt-4">
        {{ "cont_qualt1" | translate }}
      </p>
      <p>
        {{ "cont_qualt2" | translate }}
      </p>
      <p>
        {{ "cont_qualt3" | translate }}
      </p>
    </div>
  </div>
  <div class="py-5 page-element">
    <div class="row row-cols-1 row-cols-md-2 align-items-center g-4">
      <div class="col">
        <h2>{{ "cond_acc" | translate }}</h2>
        <ol>
          <li>
            <p class="mt-2">{{ "cond_accl1" | translate }}</p>
          </li>
          <li>
            <p>
              {{ "cond_accl2" | translate }}
              <a routerLink="/products">
                {{ "voir_plus" | translate }}
              </a>
            </p>
          </li>
          <li>
            <p>{{ "cond_accl3" | translate }}</p>
          </li>
        </ol>
      </div>
      <div class="col image-end">
        <img
          src="assets/images/subcontracting-handshake.png"
          alt="Handshake"
          class="img-fluid rounded"
        />
      </div>
    </div>
  </div>
  <div class="py-5 bg-white page-element">
    <div class="">
      <h2>{{ "Confid" | translate }}</h2>
      <p class="mt-2">{{ "Confid_txt" | translate }}</p>
      <h2 class="mt-5">{{ "plus" | translate }}</h2>
      <div
        class="row mb-2 d-flex justify-content-evenly justify-content-md-start"
      >
        <div class="ps-2 col-auto">
          <span>
            <img class="pe-2" src="assets/icons/mail.svg" alt="Mail svg" />
            <span style="text-decoration: underline">
              <a href="mailto:{{ contactInfo.email_marketing }}">{{
                contactInfo.email_technique
              }}</a></span
            >
          </span>
        </div>
        <div class="ps-2 col-auto">
          <span>
            <img class="pe-2" src="assets/icons/phone.svg" alt="Phone svg" />
            <a href="tel:{{ contactInfo.phone_technique_1 }}">{{
              contactInfo.phone_technique_1
            }}</a>
            /
            <a href="tel:{{ contactInfo.phone_technique_2 }}">{{
              contactInfo.phone_technique_2
            }}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row row-cols-1 row-cols-md-auto g-4 text-center mb-4 d-flex justify-content-center page-element"
  >
    <div class="col d-flex align-items-center justify-content-center">
      <img
        src="assets/images/iso13485.png"
        alt="ISO 13485"
        class="img-fluid mx-5"
      />
    </div>
    <div class="col d-flex align-items-center justify-content-center">
      <img
        src="assets/images/GMP.png"
        alt="GMP Quality"
        class="img-fluid mx-5"
      />
    </div>
    <div class="col d-flex align-items-center justify-content-center">
      <img
        src="assets/images/iso9001.png"
        alt="ISO 9001"
        class="img-fluid mx-5"
      />
    </div>
  </div>
</div>
