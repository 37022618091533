import { Action, createReducer, on } from '@ngrx/store';
import { IBlogPageState } from '../../../core/models/store-typings.model';
import {
  blogListFetchedAction,
  blogAddedAction,
  blogUpdatedAction,
  blogDeletedAction,
  blogDetailsFetchedAction,
  clearBlogFormAction,
} from './blog.actions';

export const blogInitialState: IBlogPageState = {
  blogDetails: {
    DataAR: {
      blogContent: '',
      title: '',
      author: '',
      location: '',
    },
    DataEN: {
      blogContent: '',
      title: '',
      author: '',
      location: '',
    },
    DataFR: {
      blogContent: '',
      title: '',
      author: '',
      location: '',
    },
    id: '',
    postDate: '',
    coverSrc: '',
  },
  bloglist: [],
  blogAdded: false,
};

const BlogPageReducer = createReducer(
  blogInitialState,
  on(blogListFetchedAction, (state, { payload }) => ({
    ...state,
    bloglist: payload,
  })),
  on(blogDetailsFetchedAction, (state, { payload }) => ({
    ...state,
    blogDetails: payload,
  })),
  on(blogAddedAction, (state, { payload }) => ({
    ...state,
    bloglist: [...state.bloglist, payload],
    blogAdded: true,
  })),
  on(clearBlogFormAction, (state) => ({
    ...state,
    blogAdded: false,
  })),
  on(blogUpdatedAction, (state, { payload }) => ({
    ...state,
    bloglist: state.bloglist.map((blog) =>
      blog.id === payload.id ? payload : blog
    ),
    blogAdded: true,
  })),
  on(blogDeletedAction, (state, { payload }) => ({
    ...state,
    bloglist: state.bloglist.filter((blog) => blog.id !== payload),
  }))
);

export function blogPageRootReducer(
  state: IBlogPageState | undefined,
  action: Action
) {
  return BlogPageReducer(state, action);
}
