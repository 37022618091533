import { Action, createReducer, on } from '@ngrx/store';
import { ILoginPageState } from '../../../core/models/store-typings.model';
import {
  logInSuccessAction,
  LogInFailureAction,
  logOutAction,
  TryLoginUserAction,
} from './login.actions';

export const loginInitialState: ILoginPageState = {
  isAuthenticated: false,
  token: '',
  errorMessage: null,
};

const loginPageReducer = createReducer(
  loginInitialState,
  on(
    logInSuccessAction,
    (state, { payload }): ILoginPageState => ({
      ...state,
      isAuthenticated: payload.isAuthenticated,
      token: payload.token,
    })
  ),
  on(
    LogInFailureAction,
    (state, action): ILoginPageState => ({
      ...state,
      errorMessage: action.payload,
    })
  ),
  on(TryLoginUserAction, (state) => ({
    ...state,
    errorMessage: '',
  })),
  on(logOutAction, () => loginInitialState)
);

export function loginPageRootReducer(
  state: ILoginPageState | undefined,
  action: Action
): ILoginPageState {
  return loginPageReducer(state, action);
}
