import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProductCarouselItemComponent } from './product-carousel-item/product-carousel-item.component';
import {
  ProductAttributes,
  ProductCarousel,
} from '../../core/models/typings.models';
import { LanguageService } from '../../core/services/LanguageService/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-carousel',
  standalone: true,
  imports: [CommonModule, TranslateModule, ProductCarouselItemComponent],
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
})
export class ProductCarouselComponent implements OnInit {
  @Input() newProducts: ProductAttributes[] = [];
  @Input() categories: ProductCarousel[] = [];
  @Input() isLargeScreen: boolean = false;
  @Input() currentNewProductIndex: number = 0;
  @Input() heading: string = 'category_title'; // Default value
  @Input() description: string = 'category_description'; // Default value

  @Output() prevProduct: EventEmitter<string> = new EventEmitter();
  @Output() nextProduct: EventEmitter<string> = new EventEmitter();

  currentLanguage: string = '';
  title = '';
  itemsContent: ProductCarousel[] = [];
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private languageService: LanguageService,
        private router: Router,
  ) {}
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.currentLanguage = this.languageService.getTranslation().currentLang;
      this.updateItemsContent();

      this.languageService.getTranslation().onLangChange.subscribe((event) => {
        this.currentLanguage = event.lang;
        this.updateItemsContent();
      });
    }

    if (this.newProducts.length > 0) {
      this.updateItemsContent();
    } else {
      this.itemsContent = [...this.categories];
    }
  }

  updateItemsContent(): void {
    if (this.newProducts.length > 0) {
      this.itemsContent = this.newProducts.map((product) => {
        let title: string;
        let category: string;
        switch (this.currentLanguage) {
          case 'ar':
            title = product.lanAR.title;
            category = product.lanAR.type;
            break;
          case 'fr':
            title = product.lanFR.title;
            category = product.lanFR.type;
            break;
          default:
            title = product.lanEN.title;
            category = product.lanEN.type;
            break;
        }

        return {
          image: product.imgsrc[0],
          category: category,
          title: title,
        };
      });
    }
  }

  get currentSlice(): any[] {
    return this.itemsContent.slice(
      this.currentNewProductIndex,
      this.currentNewProductIndex + (this.isLargeScreen ? 3 : 1)
    );
  }

  onPrev() {
    this.prevProduct.emit('new');
  }

  onNext() {
    this.nextProduct.emit('new');
  }
  navigateToProducts() {
    this.router.navigate(['/products']);
  }
}
