import { createAction, props } from '@ngrx/store';
import {
  ProductAttributes,
  TypeAttributes,
} from '../../../core/models/typings.models';

export const getProductListAction = createAction(
  '[Product Page][ UI] get Product List'
);
export const productListFetchedAction = createAction(
  '[Product Page][ UI] Product List Fetched',
  props<{ payload: ProductAttributes[] }>()
);
export const getSelectedProductAction = createAction(
  '[Product Page][ UI] get selected Product',
  props<{ payload: string }>()
);
export const productDetailsFetchedAction = createAction(
  '[Product Page][ UI] Product Details Fetched',
  props<{ payload: ProductAttributes }>()
);

export const getProductTypeListAction = createAction(
  '[Product Page][ UI] get Product Type List'
);
export const productTypeListFetchedAction = createAction(
  '[Product Page][ UI] Product Type List Fetched',
  props<{ payload: TypeAttributes[] }>()
);

export const addProductAction = createAction(
  '[Product Page][ UI] add Product',
  props<{ payload: ProductAttributes }>()
);
export const productAddedAction = createAction(
  '[Product Page][ UI] Product Added',
  props<{ payload: ProductAttributes }>()
);

export const clearProductFormAction = createAction(
  '[Product] Clear Product Form'
);

export const updateProductAction = createAction(
  '[Product Page][ UI] update Product',
  props<{ payload: ProductAttributes }>()
);
export const productUpdatedAction = createAction(
  '[Product Page][ UI] Product Updated',
  props<{ payload: ProductAttributes }>()
);

export const deleteProductAction = createAction(
  '[Product Page][ UI] delete Product',
  props<{ payload: string }>()
);
export const productDeletedAction = createAction(
  '[Product Page][ UI] Product Deleted',
  props<{ payload: string }>()
);
