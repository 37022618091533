import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AppState } from '../../../core/models/store-typings.model';
import {
  logOutAction,
  routeIfAlreadyLoggedAction,
  TryLoginUserAction,
} from '../store/login.actions';
import { ILoginCredentials } from '../../../core/models/typings.models';

@Injectable({
  providedIn: 'root',
})
export class LoginFacade {
  constructor(private store: Store<AppState>, private router: Router) {}

  // Check if the user is already logged in
  public checkAlreadyLoggedIn() {
    this.store.dispatch(routeIfAlreadyLoggedAction());
  }

  // Dispatch login action with user credentials
  public tryLogingUser(user: ILoginCredentials): void {
    this.store.dispatch(TryLoginUserAction({ payload: user }));
  }

  // Get error message from store (for displaying in the component)
  public get errorLoginMsg(): Observable<string | null> {
    return this.store.select((state) => state.loginPage.errorMessage);
  }

  // Log out the user and redirect to the login page
  public logOut(): void {
    this.router.navigate(['/login'], { replaceUrl: true }).then((res) => {
      if (res === true) {
        this.store.dispatch(logOutAction());
      }
    });
  }
}
