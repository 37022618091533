import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const API_URL = 'http://127.0.0.1:4210';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  // Helper method for building full URL
  private buildUrl(path: string): string {
    return `${API_URL}${path}`;
  }

  // Error formatting method
  private formatErrors(httpError: HttpErrorResponse): Observable<never> {
    const errorMessage =
      httpError.error?.message ||
      (typeof httpError.error === 'string'
        ? httpError.error
        : httpError.message) ||
      'Unexpected Error';

    return throwError(() => new Error(errorMessage));
  }

  public get<T>(
    path: string,
    options: { params?: HttpParams; headers?: HttpHeaders } = {}
  ): Observable<T> {
    return this.http
      .get<T>(this.buildUrl(path), options) // Use options for both params and headers
      .pipe(catchError(this.formatErrors));
  }

  public patch<T>(path: string, body: object = {}): Observable<T> {
    return this.http
      .patch<T>(this.buildUrl(path), body)
      .pipe(catchError(this.formatErrors));
  }

  public put<T>(path: string, body: object = {}): Observable<T> {
    return this.http
      .put<T>(this.buildUrl(path), body)
      .pipe(catchError(this.formatErrors));
  }

  public post<T>(path: string, body: object = {}): Observable<T> {
    return this.http
      .post<T>(this.buildUrl(path), body)
      .pipe(catchError(this.formatErrors));
  }

  public delete<T>(path: string, options: { body?: any } = {}): Observable<T> {
    return this.http
      .delete<T>(this.buildUrl(path))
      .pipe(catchError(this.formatErrors));
  }
}
