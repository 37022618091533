<div class="container my-5">
  <div class="text-center my-4">
    <h1 class="display-5 fw-bold text-primary mb-3">
      {{ title }}
    </h1>
    <p class="text-muted">
      {{ "published_on" | translate }} {{ Blog.postDate }}
      <i class="bi bi-geo-alt fs-6"></i>
      {{ location }}
    </p>
  </div>
<div>
  <img
    src="{{Blog.coverSrc }}"
    class="row mb-5 content-section text-start"
    alt="Blog Cover"
  />
  <div class="row mb-5 content-section text-start">
    <div #BlogContent class="col-md-12">
    </div>
  </div>
</div>

<div class="social-links position-fixed mt-5 pt-5 p-2">
  <a
    href="https://www.facebook.com/aldismed"
    class="text-secondary d-block mb-2 fs-5"
    ><i class="bi bi-facebook"></i
  ></a>
  <a
    a
    href="https://www.linkedin.com/company/aldismed/"
    class="text-secondary d-block mb-2 fs-5"
    ><i class="bi bi-linkedin"></i
  ></a>
  <a
    href="https://www.instagram.com/sarl_aldismed/"
    class="text-secondary d-block fs-5"
    ><i class="bi bi-instagram"></i
  ></a>
</div>
<div class="text-center mt-4 mb-4">
  <button class="btn-outline-custom" (click)="navigateToBlogs()">
    &larr; {{ "back_to_blogs" | translate }}
  </button>
</div>
