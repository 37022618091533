import { inject, Injectable } from '@angular/core';
import { UploadService } from '../services/upload.services';
import { ErrorHandlerService } from '../../../core/services/error/errorHander.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  map,
  catchError,
  EMPTY,
  of,
  forkJoin,
  tap,
  finalize,
} from 'rxjs';
import {
  deleteFileAction,
  deleteFileFailureAction,
  deleteFileSuccessAction,
  deleteUploadedFilesAction,
  UploadedFilesDeletedFailureAction,
  UploadedFilesDeletedSuccessfulyAction,
  uploadImageAction,
  uploadImageSuccessAction,
  uploadPdfAction,
  uploadPdfSuccessAction,
} from './upload.actions';
import { SpinnerService } from '../../spinner/SpinnerService';

@Injectable({ providedIn: 'root' })
export class UploadEffect {
  private action$ = inject(Actions);
  private uploadService = inject(UploadService);
  private errorHandler = inject(ErrorHandlerService);
  private spinnerService: SpinnerService = inject(SpinnerService);

  uploadImage$ = createEffect(() =>
    this.action$.pipe(
      ofType(uploadImageAction),
      tap(() => this.spinnerService.showSpinner()),
      mergeMap(({ imageFile, sourceFromRichText }) =>
        this.uploadService.uploadImage(imageFile, sourceFromRichText).pipe(
          map((response) => {
            return uploadImageSuccessAction({
              imageUrl: response.url,
              sourceFromRichText: response.sourceFromRichText,
            });
          }),
          catchError((err) => {
            this.errorHandler.handleError('Upload Image', err);
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner())
        )
      )
    )
  );

  uploadPdf$ = createEffect(() =>
    this.action$.pipe(
      ofType(uploadPdfAction),
      tap(() => this.spinnerService.showSpinner()),
      mergeMap(({ pdfFile, sourceFromRichText }) =>
        this.uploadService.uploadPdf(pdfFile, sourceFromRichText).pipe(
          map(({ url, sourceFromRichText }) => {
            return uploadPdfSuccessAction({
              pdfUrl: url,
              sourceFromRichText,
            });
          }),
          catchError((err) => {
            this.errorHandler.handleError('Upload Pdf', err);
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner())
        )
      )
    )
  );

  deleteFile$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteFileAction),
      tap(() => this.spinnerService.showSpinner()),
      mergeMap((action) =>
        this.uploadService.deleteFile(action.fileUrl).pipe(
          map(() => {
            return deleteFileSuccessAction({ fileUrl: action.fileUrl });
          }),
          catchError((err) => {
            this.errorHandler.handleError('Delete File', err);
            return of(deleteFileFailureAction({ error: err.message }));
          }),
          finalize(() => this.spinnerService.hideSpinner())
        )
      )
    )
  );

  deleteImagesAndFile$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteUploadedFilesAction),
      tap(() => this.spinnerService.showSpinner()),
      mergeMap(({ fileUrls }) =>
        forkJoin(
          fileUrls.map((fileUrl) =>
            this.uploadService.deleteFile(fileUrl).pipe(
              catchError((err) => {
                // Handle individual file deletion error, but don't fail the entire process
                this.errorHandler.handleError(
                  `Delete File ${fileUrl} from FTP`,
                  err
                );
                return of(null); // Return null for failed deletions
              }),
              finalize(() => this.spinnerService.hideSpinner())
            )
          )
        ).pipe(
          map((results) => {
            // Check if all files were deleted successfully
            const allSuccess = results.every((result) => result !== null);
            if (allSuccess) {
              return UploadedFilesDeletedSuccessfulyAction();
            } else {
              return UploadedFilesDeletedFailureAction({
                error: 'Some files failed to delete.',
              });
            }
          }),
          catchError((err) => {
            // Handle error in the forkJoin itself
            this.errorHandler.handleError('Delete Files from FTP', err);
            return of(
              UploadedFilesDeletedFailureAction({ error: err.message })
            );
          }),
          finalize(() => this.spinnerService.hideSpinner())
        )
      )
    )
  );
}
