import { Component, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { ToolbarComponent } from './modules/toolbar/toolbar.component';
import { LinksAndProductsComponent } from './modules/links-and-products/links-and-products.component';
import { Router, NavigationEnd, ActivationEnd, RouterOutlet, RouterModule } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { BreadcrumbComponent } from './modules/breadcrumb/breadcrumb.component';
import { LanguageService } from './core/services/LanguageService/language.service';
import { FontService } from './core/services/font-service';
import { AlertsComponent } from "./shared/alerts-component/alerts.component";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ToolbarComponent,
    LinksAndProductsComponent,
    CommonModule,
    NgxPaginationModule,
    BreadcrumbComponent,
    AlertsComponent,
    RouterModule,
    SpinnerComponent
],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'aldismed-web-app';
  showFooter: boolean = true;
  showNavbar: boolean = true;
  @ViewChild(BreadcrumbComponent) breadcrumbComponent!: BreadcrumbComponent;

  private hiddenFooterRoutes: string[] = [
    '/login',
    '/bloglist',
    '/productlist',
    '/addproduct',
    '/addblog',
  ];
  private hiddenNavbarRoutes: string[] = [
    '/login',
    '/bloglist',
    '/productlist',
    '/addproduct',
    '/addblog',
  ];
  private hiddenBreadcrumbRoutes: string[] = ['/', '/login', '/blog'];

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private fontService: FontService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.languageService.addLanguages();
    }
    this.languageService.getTranslation().onLangChange.subscribe((event) => {
      this.appplayLng(event.lang)
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.adjustVisibility(event);
        this.appplayLng(this.languageService.getTranslation().currentLang)
      }

      if (event instanceof ActivationEnd) {
        this.appplayLng(this.languageService.getTranslation().currentLang)
      }

    });

  }
  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentRoute = this.router.url.split('?')[0];
        const hasIdParam = this.router.url.includes('id=');
        
        if (
          this.hiddenFooterRoutes.includes(currentRoute) ||
          (((currentRoute === '/addproduct')||(currentRoute === '/addblog')) && hasIdParam)
        ) {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }
      });
  }

  private adjustVisibility(event: NavigationEnd) {
    this.showFooter = !this.hiddenFooterRoutes.some(
      (route) => event.urlAfterRedirects === route
    );
    this.updateBreadcrumbVisibility(event.urlAfterRedirects);
    this.showNavbar = !this.hiddenNavbarRoutes.some((route) =>
      event.url.startsWith(route)
    );
  }

  private updateBreadcrumbVisibility(url: string) {
    let shouldHideBreadcrumb = this.hiddenBreadcrumbRoutes.includes(url);

    if (this.breadcrumbComponent) {
      this.breadcrumbComponent.showBreadcrumb = !shouldHideBreadcrumb;
    }
  }
  private appplayLng(lng: string) {
    if (lng === 'ar') {
      this.fontService.applyRtlFont();
    } else {
      this.fontService.applyLtrFont();
    }
  }

}
