import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ProductAttributes,
  TypeAttributes,
} from '../../../core/models/typings.models';
import { productQueries } from '../store/product.selector';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/models/store-typings.model';
import {
  getProductListAction,
  getSelectedProductAction,
  getProductTypeListAction,
  addProductAction,
  updateProductAction,
  deleteProductAction,
  clearProductFormAction,
} from '../store/product.actions';

@Injectable({ providedIn: 'root' })
export class ProductFacade {
  constructor(private store: Store<AppState>) {}

  public fetchProductList(): Observable<ProductAttributes[]> {
    return this.store.pipe(select(productQueries.productListQuery));
  }

  public getProductDetail(): Observable<ProductAttributes> {
    return this.store.pipe(select(productQueries.productDetailQuery));
  }

  public fetchProductTypes(): Observable<TypeAttributes[]> {
    return this.store.pipe(select(productQueries.productTypeListQuery));
  }
  public productAdded() {
    return this.store.pipe(select(productQueries.productAddedQuery));
  }
  public clearProductForm(): void {
    return this.store.dispatch(clearProductFormAction()); // Dispatch action to reset the flag
  }

  public loadProductList(): void {
    this.store.dispatch(getProductListAction());
  }

  public loadSelectedProduct(productId: string): void {
    this.store.dispatch(getSelectedProductAction({ payload: productId }));
  }

  public loadProductTypes(): void {
    this.store.dispatch(getProductTypeListAction());
  }

  public addProduct(product: ProductAttributes): void {
    this.store.dispatch(addProductAction({ payload: product }));
  }

  public updateProduct(product: ProductAttributes): void {
    this.store.dispatch(updateProductAction({ payload: product }));
  }

  public deleteProduct(productId: string): void {
    this.store.dispatch(deleteProductAction({ payload: productId }));
  }
}
