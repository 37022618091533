import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BlogAttributes } from '../../../../../core/models/typings.models';
import { BlogFacade } from '../../../../blog/services/blog-facade.service';

@Component({
  selector: 'app-blog-list-item',
  standalone: true,
  imports: [],
  templateUrl: './blog-list-item.component.html',
  styleUrl: './blog-list-item.component.scss'
})
export class BlogListItemComponent {
  @Input() blog: BlogAttributes = {} as BlogAttributes;

  constructor(private router: Router, private blogFacade: BlogFacade) { }
  navigateToEditBlog() {
    this.router.navigate(['/addblog'], { queryParams: { id: this.blog.id } });

  }
  async deleteBlog(): Promise<void> {
    if (confirm('Etes-vous sûr de vouloir supprimer ce blog ?')) {
      try {
        await this.blogFacade.deleteBlog(this.blog.id);
        this.router.navigate(['/bloglist']);
      } catch (error) {
        console.error('Erreur lors de la suppression du blog :', error);
      }
    }
  }
}
