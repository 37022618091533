import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlogAttributes } from '../../../core/models/typings.models';
import { blogQueries } from '../store/blog.selector';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/models/store-typings.model';
import {
  getBlogListAction,
  addBlogAction,
  updateBlogAction,
  deleteBlogAction,
  getSelectedBlogAction,
  clearBlogFormAction,
} from '../store/blog.actions';

@Injectable({ providedIn: 'root' })
export class BlogFacade {
  constructor(private store: Store<AppState>) {}

  public fetchBlogList(): Observable<BlogAttributes[]> {
    return this.store.pipe(select(blogQueries.blogListQuery));
  }

  public getBlogDetail(): Observable<BlogAttributes> {
    return this.store.pipe(select(blogQueries.blogDetailsQuery));
  }
  public loadSelectedBlog(blogId: string): void {
    this.store.dispatch(getSelectedBlogAction({ payload: blogId }));
  }

  public loadBlogList(): void {
    this.store.dispatch(getBlogListAction());
  }

  public addBlog(blog: BlogAttributes): void {
    this.store.dispatch(addBlogAction({ payload: blog }));
  }

  public updateBlog(blog: BlogAttributes): void {
    this.store.dispatch(updateBlogAction({ payload: blog }));
  }

  public deleteBlog(blogId: string): void {
    this.store.dispatch(deleteBlogAction({ payload: blogId }));
  }

  public blogAdded() {
    return this.store.pipe(select(blogQueries.blogAddedQuery));
  }
  public clearBlogForm(): void {
    return this.store.dispatch(clearBlogFormAction());
  }
}
