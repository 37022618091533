import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertsService } from './alerts.service';
import { takeUntil } from 'rxjs/operators';
import { IAlert } from '../../core/models/typings.models';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  imports: [CommonModule], 
})
export class AlertsComponent implements OnInit, OnDestroy {
  public alertIsShowing = false;
  public text = '';
  public style = 'basic';
  public destroy$ = new Subject();
  constructor(private el: ElementRef, private alertService: AlertsService) {}
  onClose() {
    this.alertIsShowing = false;
  }
  showAlert(data: IAlert) {
    this.text = data.text;
    this.style = data.style ? data.style : 'basic';
    data.duration ? null : (data.duration = 2000);
    this.alertIsShowing = true;
    setTimeout(() => {
      this.alertIsShowing = false;
    }, data.duration);
  }

  ngOnInit() {
    this.alertService.alertsFeed$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.showAlert(res));
  }

  ngOnDestroy() {
    this.destroy$.next('');
  }
}
