<div class="fade-in-left hero-section">
  <div class="overlay"></div>
  <div class="overlay-text">
      <h1>{{ "blog_title" | translate }}</h1>
      <p>{{ "blog_text" | translate }}</p>

  </div>
</div>
<div class="fade-in-left max-width-container">
  <div class="my-4" id="blog-list">
    <div class="row row-cols-1 row-cols-md-3 g-4 m-0">
      <div
        class="col"
        *ngFor="let blog of blogs | paginate : { itemsPerPage: itemsPerPage, currentPage: currentPage }"
      >
        <div class="card blog-item-wrapper">
          <app-blog-item [blog]="blog"></app-blog-item>
        </div>
      </div>
    </div> 

    <div class="pagination-container mt-4">
      <pagination-controls
        (pageChange)="onPageChange($event)"
        [autoHide]="true"
        [directionLinks]="true"
        previousLabel=""
        nextLabel=""
      ></pagination-controls>
    </div>
  </div>
</div>
