import { Injectable, inject } from '@angular/core';
import { ErrorHandlerService } from '../../../core/services/error/errorHander.service';
import { ProductService } from '../services/product.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  map,
  catchError,
  EMPTY,
  switchMap,
  tap,
  finalize,
} from 'rxjs';
import { AlertsService } from '../../../shared/alerts-component/alerts.service';
import {
  getProductListAction,
  productListFetchedAction,
  addProductAction,
  productAddedAction,
  updateProductAction,
  productUpdatedAction,
  deleteProductAction,
  productDeletedAction,
  getSelectedProductAction,
  productDetailsFetchedAction,
} from './product.actions';
import { SpinnerService } from '../../../shared/spinner/SpinnerService';

@Injectable({ providedIn: 'root' })
export class ProductsEffect {
  private action$ = inject(Actions);
  private productService = inject(ProductService);
  private errorHandler = inject(ErrorHandlerService);
  private alertsService = inject(AlertsService);
  private spinnerService: SpinnerService = inject(SpinnerService);

  loadProductList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getProductListAction),
      switchMap(() =>
        this.productService.getProductList().pipe(
          map((res) => productListFetchedAction({ payload: res })),
          catchError((err) => {
            this.errorHandler.handleError('Liste des Produits', err);
            this.alertsService.showAnAlert({
              text: 'Échec du chargement des produits.',
              style: 'warning',
              duration: 3000,
            });
            return EMPTY;
          })
        )
      )
    )
  );
  
  loadProductDetails$ = createEffect(() =>
    this.action$.pipe(
      ofType(getSelectedProductAction),
      tap(() => this.spinnerService.showSpinner()),
      mergeMap((action) =>
        this.productService.getProductDetails(action.payload).pipe(
          map((res) => productDetailsFetchedAction({ payload: res })),
          catchError((err) => {
            this.errorHandler.handleError('Détails du Produit', err);
            this.alertsService.showAnAlert({
              text: 'Échec du chargement des détails du produit.',
              style: 'warning',
              duration: 3000,
            });
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner())
        )
      )
    )
  );

  addProduct$ = createEffect(() =>
    this.action$.pipe(
      ofType(addProductAction),
      tap(() => this.spinnerService.showSpinner()),
      mergeMap((action) =>
        this.productService.addProductList(action.payload).pipe(
          map((res) => productAddedAction({ payload: res })),
          tap(() => {
            this.alertsService.showAnAlert({
              text: 'Produit ajouté avec succès !',
              style: 'success',
              duration: 3000,
            });
          }),
          catchError((err) => {
            this.errorHandler.handleError('Ajout de Produit', err);
            this.alertsService.showAnAlert({
              text: "Échec de l'ajout du produit.",
              style: 'warning',
              duration: 3000,
            });
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner())
        )
      )
    )
  );

  updateProduct$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateProductAction),
      tap(() => this.spinnerService.showSpinner()),
      mergeMap((action) =>
        this.productService.updateProductList(action.payload).pipe(
          map((res) => productUpdatedAction({ payload: res })),
          tap(() => {
            this.alertsService.showAnAlert({
              text: 'Produit mis à jour avec succès !',
              style: 'success',
              duration: 3000,
            });
          }),
          catchError((err) => {
            this.errorHandler.handleError('Mise à Jour de Produit', err);
            this.alertsService.showAnAlert({
              text: 'Échec de la mise à jour du produit.',
              style: 'warning',
              duration: 3000,
            });
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner())
        )
      )
    )
  );

  deleteProduct$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteProductAction),
      tap(() => this.spinnerService.showSpinner()),
      mergeMap((action) =>
        this.productService.deleteProductList(action.payload).pipe(
          map(() => productDeletedAction({ payload: action.payload })),
          tap(() => {
            this.alertsService.showAnAlert({
              text: 'Produit supprimé avec succès !',
              style: 'success',
              duration: 3000,
            });
          }),
          catchError((err) => {
            this.errorHandler.handleError('Suppression de Produit', err);
            this.alertsService.showAnAlert({
              text: 'Échec de la suppression du produit.',
              style: 'warning',
              duration: 3000,
            });
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner())
        )
      )
    )
  );
}
