import { Action, createReducer, on } from '@ngrx/store';
import { IUploadPageState } from '../../../core/models/store-typings.model';
import {
  ClearImageUrlFromStoreAction,
  ClearPdfUrlFromStoreAction,
  deleteFileSuccessAction,
  resetImageDeletedValueAction,
  UploadedFilesDeletedSuccessfulyAction,
  uploadImageSuccessAction,
  uploadPdfSuccessAction,
} from './upload.actions';

export const uploadInitialState: IUploadPageState = {
  UploadedImage: { url: '', sourceFromRichText: false },
  UploadedPdf: { url: '', sourceFromRichText: false },
  imagesDeleted: false,
};

const UploadPageReducer = createReducer(
  uploadInitialState,
  on(uploadImageSuccessAction, (state, { imageUrl, sourceFromRichText }) => ({
    ...state,
    UploadedImage: { url: imageUrl, sourceFromRichText },
  })),
  on(ClearImageUrlFromStoreAction, (state) => ({
    ...state,
    UploadedImage: { url: '', sourceFromRichText: false },
  })),
  on(uploadPdfSuccessAction, (state, { pdfUrl, sourceFromRichText }) => ({
    ...state,
    UploadedPdf: { url: pdfUrl, sourceFromRichText },
  })),
  on(ClearPdfUrlFromStoreAction, (state) => ({
    ...state,
    UploadedPdf: { url: '', sourceFromRichText: false },
  })),
  on(deleteFileSuccessAction, (state) => ({
    ...state,
    UploadedImage: { url: '', sourceFromRichText: false },
    UploadedPdf: { url: '', sourceFromRichText: false },
  })),
  on(UploadedFilesDeletedSuccessfulyAction, (state) => ({
    ...state,
    imagesDeleted: true,
  })),
  on(resetImageDeletedValueAction, (state) => ({
    ...state,
    imagesDeleted: false,
  }))
);

export function uploadPageRootReducer(
  state: IUploadPageState | undefined,
  action: Action
) {
  return UploadPageReducer(state, action);
}
