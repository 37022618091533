import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { Observable } from 'rxjs';
import {
  ProductAttributes,
  TypeAttributes,
} from '../../../core/models/typings.models';
@Injectable({ providedIn: 'root' })
export class ProductService {
  constructor(private apiService: ApiService) { }

  getProductList(): Observable<ProductAttributes[]> {
    const url = `/product`;
    return this.apiService.get<ProductAttributes[]>(url);
  }

  getProductDetails(id: string): Observable<ProductAttributes> {
    const url = `/product/${id}`;
    return this.apiService.get<ProductAttributes>(url);
  }

  getProductTypeList(): Observable<TypeAttributes[]> {
    const url = `/product/types`;
    return this.apiService.get<TypeAttributes[]>(url);
  }

  addProductList(product: ProductAttributes): Observable<ProductAttributes> {
    const url = `/product`;
    return this.apiService.post<ProductAttributes>(url, product);
  }

  deleteProductList(id: string): Observable<boolean> {
    const url = `/product/${id}`;
    return this.apiService.delete<boolean>(url);
  }

  updateProductList(product: ProductAttributes): Observable<ProductAttributes> {
    const url = `/product/${product.id}`;
    return this.apiService.patch<ProductAttributes>(url, product);
  }
}
