import { Component, Input } from '@angular/core';
import { BlogItemComponent } from '../blog-item/blog-item.component';
import { BlogAttributes } from '../../../../core/models/typings.models';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-showcase',
  standalone: true,
  imports: [BlogItemComponent,CommonModule],
  templateUrl: './blog-showcase.component.html',
  styleUrl: './blog-showcase.component.scss'
})
export class BlogShowcaseComponent  {
  @Input() blogs: BlogAttributes[] | null = null;

constructor(    private router: Router,
){}
navigateToBlogs(): void {
    this.router.navigate(['/blog']);
  }

}
