import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BlogAttributes } from '../../../../core/models/typings.models';
import { isPlatformBrowser } from '@angular/common';
import { LanguageService } from '../../../../core/services/LanguageService/language.service';

@Component({
  selector: 'app-blog-item',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './blog-item.component.html',
  styleUrls: ['./blog-item.component.scss'],
})
export class BlogItemComponent implements OnInit {
  @Input() blog: BlogAttributes = {} as BlogAttributes;
  description: string = '';
  currentLanguage: string = 'FR'; // Default language
  title: string = '';
  location: string = '';

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.currentLanguage = this.languageService.getTranslation().currentLang;
      this.updateLanguageDependentFields();

      this.languageService.getTranslation().onLangChange.subscribe((event) => {
        this.currentLanguage = event.lang;
        this.updateLanguageDependentFields();
      });
    }
  }

  navigateToDetail(): void {
    this.router.navigate(['/blog', this.blog.id]);
  }

  updateLanguageDependentFields(): void {
    let blogData = null;

    if (this.currentLanguage === 'ar') {
      blogData = this.blog.DataAR;
    } else if (this.currentLanguage === 'en') {
      blogData = this.blog.DataEN;
    } else if (this.currentLanguage === 'fr') {
      blogData = this.blog.DataFR;
    }

    if (blogData) {
      this.title = blogData.title;
      this.location = blogData.location;
      this.description = this.extractFirstParagraph(blogData.blogContent);
    }
  }

  extractFirstParagraph(content: string, maxLength: number = 100): string {
    const matches = content.match(/<p>(.*?)<\/p>/gs);
    if (!matches) return '';

    const extractedText = matches
      .map((match) => match.replace(/<p>|<\/p>/g, '').trim())
      .join(' ');

    return extractedText.length > maxLength
      ? extractedText.slice(0, maxLength) + '...'
      : extractedText;
  }
}
