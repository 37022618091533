import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from '../../../core/api/api.service';
import { UploadedFiles } from '../../../core/models/typings.models';

@Injectable({ providedIn: 'root' })
export class UploadService {
  constructor(private apiService: ApiService) {}

  uploadImage(
    imageFile: File,
    sourceFromRichText: boolean
  ): Observable<UploadedFiles> {
    const url = `/upload-image`;
    const formData = new FormData();

    formData.append('image', imageFile);
    formData.append('sourceFromRichText', String(sourceFromRichText));

    return this.apiService
      .post<{
        message: string;
        response: { url: string; sourceFromRichText: boolean };
      }>(url, formData)
      .pipe(map((res) => res.response));
  }

  uploadPdf(
    pdfFile: File,
    sourceFromRichText: boolean
  ): Observable<UploadedFiles> {
    const url = `/upload-pdf`;
    const formData = new FormData();
    formData.append('pdf', pdfFile);
    formData.append('sourceFromRichText', String(sourceFromRichText));

    return this.apiService
      .post<{
        message: string;
        response: { url: string; sourceFromRichText: boolean };
      }>(url, formData)
      .pipe(map((res) => res.response));
  }

  deleteFile(
    filePath: string
  ): Observable<{ message: string; success: boolean }> {
    const url = `/delete-file`;
    console.log(filePath);
    const body = { url: filePath };

    return this.apiService.post<{ message: string; success: boolean }>(
      url,
      body
    );
  }
}
