<div class="navbar_container">
  <app-toolbar *ngIf="showNavbar"></app-toolbar>
  <app-breadcrumb></app-breadcrumb>
</div>

<main>
  <app-spinner></app-spinner>
  <app-alerts></app-alerts>
  <router-outlet></router-outlet>
</main>

<footer *ngIf="showFooter">
  <app-links-and-products></app-links-and-products>
</footer>