<div class="d-flex vh-100 force-ltr">
  <!-- Sidebar Component -->
  <app-sidebar class="sidebar"></app-sidebar>

  <!-- Main Content Area -->
  <div class="flex-grow-1 p-4 main-content">
    <div class="card p-4">
      <h2 class="mb-4">Add Blog</h2>

      <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="french-tab"
            data-bs-toggle="tab"
            href="#french"
            role="tab"
            aria-controls="french"
            aria-selected="true"
            >Français</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="english-tab"
            data-bs-toggle="tab"
            href="#english"
            role="tab"
            aria-controls="english"
            aria-selected="false"
            >Anglais</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="arabic-tab"
            data-bs-toggle="tab"
            href="#arabic"
            role="tab"
            aria-controls="arabic"
            aria-selected="false"
            >Arab</a
          >
        </li>
      </ul>

      <form [formGroup]="blogForm" novalidate class="needs-validation">
        <div class="row mb-3">
          <div class="col-md-4">
            <div class="row mb-2 m-0 d-flex align-items-center">
              <label for="coverUpload" class="col-auto form-label"
                >Upload Cover Image</label
              >
              <input
                type="file"
                class="col form-control"
                id="coverUpload"
                (change)="onFileSelected($event)"
                [ngClass]="getValidationClass('photos')"
              />
            </div>
            <div *ngIf="isInvalid('photos')" class="invalid-feedback">
              photo is required.
            </div>
            <div *ngIf="uploadedCover !== ''" class="col-12 preview-container">
              <div class="preview-item">
                <img
                  [src]="uploadedCover"
                  alt="Preview"
                  class="preview-image"
                />
                <button
                  type="button"
                  class="delete-btn delete-btn-image"
                  (click)="deleteUploadedItem(uploadedCover)"
                  aria-label="Delete Image"
                >
                  <i class="bi bi-x-circle-fill"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-4">
            <label for="date" class="form-label">Date</label>
            <input
              type="date"
              class="form-control"
              id="date"
              formControlName="blogDate"
              name="blogDate"
              [ngClass]="getValidationClass('blogDate')"
            />
            <div *ngIf="isInvalid('blogDate')" class="invalid-feedback">
              Date est obligatoire.
            </div>
          </div>
        </div>

        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="french"
            role="tabpanel"
            aria-labelledby="french-tab"
          >
            <div class="col-md-4 mb-3">
              <label for="location" class="form-label">Emplacement</label>
              <input
                type="text"
                class="form-control"
                id="location"
                formControlName="locationFR"
                name="locationFR"
                placeholder="Entrez l'emplacement"
                [ngClass]="getValidationClass('locationFR')"
              />
              <div *ngIf="isInvalid('locationFR')" class="invalid-feedback">
                L'Emplacement est obligatoire.
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="title-fr" class="form-label">Titre</label>
                <input
                  type="text"
                  class="form-control"
                  id="title-fr"
                  formControlName="titleFR"
                  name="titleFR"
                  placeholder="Titre de blog"
                  [ngClass]="getValidationClass('titleFR')"
                />
                <div *ngIf="isInvalid('titleFR')" class="invalid-feedback">
                  Titre est obligatoire.
                </div>
              </div>
              <div class="col-md-4">
                <label for="authorNameFr" class="form-label"
                  >Nom de l'auteur (Français)</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="authorNameFr"
                  placeholder="Nom de l'auteur"
                  formControlName="authorFR"
                  name="authorFR"
                  (input)="syncAuthor('FR')"
                  [ngClass]="getValidationClass('authorFR')"
                />
                <div *ngIf="isInvalid('authorFR')" class="invalid-feedback">
                  Nom de l'auteur est obligatoire.
                </div>
              </div>
            </div>
            <div class="mb-4">
              <label for="editor-fr" class="form-label-title mb-2">Texte</label>
              <editor
                id="editor-fr"
                [init]="editorInit"
                formControlName="blogContentFR"
                name="blogContentFR"
                [ngClass]="getValidationClass('blogContentFR')"
              ></editor>
              <div *ngIf="isInvalid('blogContentFR')" class="invalid-feedback">
                Le Texte est Obligatoire.
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="english"
            role="tabpanel"
            aria-labelledby="english-tab"
          >
            <div class="col-md-4 mb-3">
              <label for="location" class="form-label">Location</label>
              <input
                type="text"
                class="form-control"
                id="location"
                formControlName="locationEN"
                name="locationEN"
                placeholder="Enter location"
                [ngClass]="getValidationClass('locationEN')"
              />
              <div *ngIf="isInvalid('locationEN')" class="invalid-feedback">
                "Location" is required.
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="title-en" class="form-label">Title</label>
                <input
                  type="text"
                  class="form-control"
                  id="title-en"
                  formControlName="titleEN"
                  name="titleEN"
                  placeholder="Blog title"
                  [ngClass]="getValidationClass('titleEN')"
                />
                <div *ngIf="isInvalid('titleEN')" class="invalid-feedback">
                  Title is required.
                </div>
              </div>
              <div class="col-md-4">
                <label for="authorNameEn" class="form-label"
                  >Author Name (English)</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="authorNameEn"
                  placeholder="Author Name"
                  formControlName="authorEN"
                  name="authorEN"
                  (input)="syncAuthor('EN')"
                  [ngClass]="getValidationClass('authorEN')"
                />
                <div *ngIf="isInvalid('authorEN')" class="invalid-feedback">
                  Author Name is required.
                </div>
              </div>
            </div>
            <div class="mb-4">
              <label for="editor-en" class="form-label-title mb-2">Text</label>
              <editor
                id="editor-en"
                [init]="editorInit"
                formControlName="blogContentEN"
                name="blogContentEN"
                [ngClass]="getValidationClass('blogContentEN')"
              ></editor>
              <div *ngIf="isInvalid('blogContentEN')" class="invalid-feedback">
                Text is required
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="arabic"
            role="tabpanel"
            aria-labelledby="arabic-tab"
            style="direction: rtl"
          >
            <div class="col-md-4 mb-3">
              <label for="location" class="form-label">الموقع</label>
              <input
                type="text"
                class="form-control"
                id="location"
                formControlName="locationAR"
                name="locationAR"
                placeholder="أدخل الموقع"
                [ngClass]="getValidationClass('locationAR')"
              />
              <div *ngIf="isInvalid('locationAR')" class="invalid-feedback">
                الموقع مطلوب.
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                <label for="title-ar" class="form-label">العنوان</label>
                <input
                  type="text"
                  class="form-control"
                  id="title-ar"
                  formControlName="titleAR"
                  name="titleAR"
                  placeholder="عنوان المدونة"
                  [ngClass]="getValidationClass('titleAR')"
                />
                <div *ngIf="isInvalid('titleAR')" class="invalid-feedback">
                  العنوان مطلوب.
                </div>
              </div>
              <div class="col-md-4">
                <label for="authorNameAr" class="form-label">اسم المؤلف</label>
                <input
                  type="text"
                  class="form-control"
                  id="authorNameAr"
                  placeholder="اسم المؤلف"
                  formControlName="authorAR"
                  name="authorAR"
                  [ngClass]="getValidationClass('authorAR')"
                />
                <div *ngIf="isInvalid('authorAR')" class="invalid-feedback">
                  اسم المؤلف مطلوب.
                </div>
              </div>
            </div>
            <div class="mb-4">
              <label for="editor-ar" class="form-label-title mb-2">النص</label>
              <editor
                id="editor-ar"
                [init]="editorInit"
                formControlName="blogContentAR"
                name="blogContentAR"
                [ngClass]="getValidationClass('blogContentAR')"
              ></editor>
              <div *ngIf="isInvalid('blogContentAR')" class="invalid-feedback">
                النص مطلوب.
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-danger me-2"
            (click)="cancelRequest()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            (click)="submitForm($event)"
          >
            Submit
          </button>
        </div>
        <
      </form>
    </div>
  </div>
</div>
