import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';
import { LanguageService } from '../../core/services/LanguageService/language.service';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  currentLanguage: string = 'FR';
  @ViewChild('navbarContent') navbarContent!: ElementRef;

  constructor(
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    if (isPlatformBrowser(this.platformId)) {

      const savedLanguage = localStorage.getItem('appLanguage') || 'FR';
      this.currentLanguage = savedLanguage;
      this.languageService.setLanguage(savedLanguage)

    }
  }

  selectLanguage(language: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('appLanguage', language);
      this.currentLanguage = language;
      this.languageService.setLanguage(language);
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (this.navbarContent.nativeElement.classList.contains('show')) {
        // Collapse the navbar
        this.navbarContent.nativeElement.classList.remove('show');
      }
    });
  }
}
