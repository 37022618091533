import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-product-carousel-item',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './product-carousel-item.component.html',
  styleUrl: './product-carousel-item.component.scss',
})
export class ProductCarouselItemComponent {
  @Input() product: any;
}
