import { Component, inject, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { BlogFacade } from '../../../blog/services/blog-facade.service';
import {
  BlogAttributes,
  UploadedFiles,
} from '../../../../core/models/typings.models';
import { EditorModule } from '@tinymce/tinymce-angular';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UploadFacade } from '../../../../shared/upload/services/upload-facade.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertsService } from '../../../../shared/alerts-component/alerts.service';

@Component({
  selector: 'app-add-blog',
  standalone: true,
  imports: [
    TranslateModule,
    SidebarComponent,
    FormsModule,
    EditorModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.scss'],
})
export class AddBlogComponent {
  blogForm!: FormGroup;
  richTextImages: string[] = [];
  uploadedCover: string = '';
  selectedFile: string = '';
  isUploadingRichtextFile = false;
  submitClicked = false;
  blogId = '1';
  private alertsService = inject(AlertsService);

  destroy$ = new Subject<void>();

  editorInit = {
    apiKey: 'ncpv4d4cj0orbco0akyy8ykkzw87okos6548u45uz7cabrqr',
    height: 400,
    menubar: false,
    plugins: 'lists link image table textcolor colorpicker code',
    toolbar: `undo redo |
              bold italic underline | 
              fontfamily| 
              formatselect | 
              fontsize| 
              blocks | 
              fontsizeselect fontselect | 
              forecolor backcolor | 
              alignleft aligncenter alignright | 
              link| 
              bullist numlist | image | table `,
    font_formats:
      'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
    file_picker_types: 'file image media',
    automatic_uploads: true,

    file_picker_callback: (
      callback: (url: string, meta: { alt: string }) => void
    ) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '*/*';
      input.onchange = async () => {
        const file = input.files?.[0];
        if (file) {
          this.isUploadingRichtextFile = true;
          const isImage = file.type.startsWith('image/');

          if (isImage) {
            this.uploadFacade.uploadImage(file, true);
            this.uploadFacade
              .fetchUploadedImage()
              .pipe(takeUntil(this.destroy$))
              .subscribe((uploadedfile: UploadedFiles) => {
                if (
                  uploadedfile &&
                  uploadedfile.url &&
                  uploadedfile.sourceFromRichText === true
                ) {
                  callback(uploadedfile.url, { alt: file.name });
                  this.richTextImages.push(uploadedfile.url);
                  this.uploadFacade.clearImageUrlFromStore();
                }
                this.isUploadingRichtextFile = false;
              });
          }
        }
      };
      input.click();
    },
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private blogFacade: BlogFacade,
    private uploadFacade: UploadFacade,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.initilizeBlogForm();
  }

  ngOnInit(): void {
    this.initDate();
    this.handleUpload();
    this.handleClearForm();
    this.route.queryParams.subscribe((params: Params) => {
      const blogId = params['id'];
      if (blogId) {
        this.blogId = blogId;
        this.loadBlogData(blogId);
      }
    });
  }

  private initDate(): void {
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    this.blogForm.patchValue({
      blogDate: today,
    });
  }
  private handleUpload(): void {
    this.uploadFacade
      .fetchUploadedImage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((uploadedImage: UploadedFiles) => {
        if (
          uploadedImage &&
          uploadedImage.url &&
          uploadedImage.sourceFromRichText === false
        ) {
          this.uploadedCover = uploadedImage.url;
          this.uploadFacade.clearImageUrlFromStore();
        }
      });
  }

  deleteUploadedItem(url: string): void {
    this.uploadedCover = '';
    this.uploadFacade.deleteFile(url);
  }

  private initilizeBlogForm() {
    this.blogForm = new FormGroup({
      titleFR: new FormControl('', Validators.required),
      titleEN: new FormControl('', Validators.required),
      titleAR: new FormControl('', Validators.required),
      blogContentFR: new FormControl('', Validators.required),
      blogContentEN: new FormControl('', Validators.required),
      blogContentAR: new FormControl('', Validators.required),
      authorFR: new FormControl('', Validators.required),
      authorEN: new FormControl('', Validators.required),
      authorAR: new FormControl('', Validators.required),
      locationFR: new FormControl('', Validators.required),
      locationEN: new FormControl('', Validators.required),
      locationAR: new FormControl('', Validators.required),
      blogDate: new FormControl('', Validators.required),
    });
  }

  cancelRequest() {
    if (this.blogId !== '1') {
      this.router.navigate(['/bloglist']);
    } else {
      const filesToDelete = [this.uploadedCover, ...this.richTextImages].filter(
        (file) => file !== ''
      );

      if (filesToDelete.length > 0) {
        this.uploadFacade.deleteUploadedFiles(filesToDelete);
        this.uploadFacade
          .imagesDeleted()
          .pipe(takeUntil(this.destroy$))
          .subscribe((imagesDeleted) => {
            if (imagesDeleted) {
              this.resetFormValues();
            }
          });
      } else {
        this.resetFormValues();
      }
    }
  }

  handleClearForm() {
    this.blogFacade
      .blogAdded()
      .pipe(takeUntil(this.destroy$))
      .subscribe((blogAdded) => {
        if (blogAdded) {
          this.resetFormValues();
          if (this.blogId !== '1') {
            this.router.navigate(['/bloglist']);
          }
        }
      });
  }

  resetFormValues() {
    this.blogForm.reset();
    this.blogFacade.clearBlogForm();
    this.uploadFacade.resetImageDeletedValue();
    this.uploadedCover = '';
    this.submitClicked = false;
    this.richTextImages = [];
  }

  getValidationClass(controlName: string): string {
    if (this.isInvalid(controlName)) {
      return 'is-invalid';
    } else if (this.isValid(controlName)) {
      return 'is-valid';
    }
    return '';
  }
  isInvalid(controlName: string): boolean {
    if (controlName === 'photos') {
      return this.uploadedCover == '' && this.submitClicked;
    } else {
      const control = this.blogForm.get(controlName);
      return control!.invalid && (control!.touched || this.submitClicked);
    }
  }

  isValid(controlName: string): boolean {
    if (controlName === 'photos') {
      return this.uploadedCover !== '' && this.submitClicked;
    } else {
      const control = this.blogForm.get(controlName);
      return control!.valid && (control!.touched || this.submitClicked);
    }
  }
  isFormValid(): boolean {
    return (
      this.blogForm.valid &&
      this.uploadedCover !== '' &&
      !this.isUploadingRichtextFile
    );
  }
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files) {
      const files = Array.from(input.files);
      files.forEach((file) => {
        this.uploadFacade.uploadImage(file);
      });
    }
    input.value = '';
  }

  async submitForm(event: Event): Promise<void> {
    event.preventDefault();
    this.submitClicked = true;

    this.blogForm
      .get('authorEN')
      ?.setValue(this.blogForm.get('authorFR')?.value);

    if (!this.isFormValid()) {
      this.alertsService.showAnAlert({
        text: 'Voulez vous remplir tous les champs.',
        style: 'warning',
        duration: 3000,
      });

      return;
    }

    const processedContent = await this.processEditorContent({
      FR: this.blogForm.get('blogContentFR')?.value,
      EN: this.blogForm.get('blogContentEN')?.value,
      AR: this.blogForm.get('blogContentAR')?.value,
    });

    const newBlog: BlogAttributes = {
      id: this.blogId,
      DataFR: {
        title: this.blogForm.get('titleFR')?.value || '',
        blogContent: processedContent.FR || '',
        author: this.blogForm.get('authorFR')?.value || '',
        location: this.blogForm.get('locationFR')?.value || '',
      },
      DataEN: {
        title: this.blogForm.get('titleEN')?.value || '',
        blogContent: processedContent.EN || '',
        author: this.blogForm.get('authorEN')?.value || '',
        location: this.blogForm.get('locationEN')?.value || '',
      },
      DataAR: {
        title: this.blogForm.get('titleAR')?.value || '',
        blogContent: processedContent.AR || '',
        author: this.blogForm.get('authorAR')?.value || '',
        location: this.blogForm.get('locationAR')?.value || '',
      },
      postDate: this.blogForm.get('blogDate')?.value || '',
      coverSrc: this.uploadedCover,
    };

    if (this.blogId === '1') {
      this.blogFacade.addBlog(newBlog);
    } else {
      this.blogFacade.updateBlog(newBlog);
    }
  }

  private async processEditorContent(content: {
    FR: string;
    EN: string;
    AR: string;
  }): Promise<{ FR: string; EN: string; AR: string }> {
    const parser = new DOMParser();
    const docFR = parser.parseFromString(content.FR, 'text/html');
    const docAR = parser.parseFromString(content.AR, 'text/html');
    const docEN = parser.parseFromString(content.EN, 'text/html');

    const imgElementsFR = Array.from(docFR.querySelectorAll('img'));
    const imgElementsAR = Array.from(docAR.querySelectorAll('img'));
    const imgElementsEN = Array.from(docEN.querySelectorAll('img'));

    const updateImageSrc = (
      img: HTMLImageElement,
      matchingImages: HTMLImageElement[]
    ) => {
      const src = img.getAttribute('src');

      if (src && !src.startsWith('data:')) {
        matchingImages.forEach((matchingImg) => {
          matchingImg.setAttribute('src', src);
        });
      }
    };

    // Update images in the French content
    for (const img of imgElementsFR) {
      const matchingImages = [
        ...imgElementsAR.filter(
          (imgAR) => imgAR.getAttribute('alt') === img.getAttribute('alt')
        ),
        ...imgElementsEN.filter(
          (imgEN) => imgEN.getAttribute('alt') === img.getAttribute('alt')
        ),
      ];
      updateImageSrc(img, matchingImages);
    }

    // Update images in the English content
    for (const img of imgElementsEN) {
      const matchingARImages = imgElementsAR.filter(
        (imgAR) => imgAR.getAttribute('alt') === img.getAttribute('alt')
      );
      updateImageSrc(img, matchingARImages);
    }

    // Add class to all table elements
    const addTableClasses = (doc: Document) => {
      const tables = Array.from(doc.querySelectorAll('table'));
      tables.forEach((table) => {
        table.classList.add('table', 'table-bordered');
      });
    };

    addTableClasses(docFR);
    addTableClasses(docAR);
    addTableClasses(docEN);

    return {
      FR: docFR.body.innerHTML,
      AR: docAR.body.innerHTML,
      EN: docEN.body.innerHTML,
    };
  }

  syncAuthor(language: string): void {
    const source = this.blogForm.get(`author${language}`)?.value;
    const targetLanguage = language === 'FR' ? 'EN' : 'FR';
    this.blogForm.get(`author${targetLanguage}`)?.setValue(source);
  }

  private loadBlogData(blogId: string): void {
    this.blogFacade.loadSelectedBlog(blogId);
    this.blogFacade
      .getBlogDetail()
      .pipe(takeUntil(this.destroy$))
      .subscribe((blog) => {
        if (blog) {
          this.blogForm.patchValue({
            titleFR: blog.DataFR.title,
            titleEN: blog.DataEN.title,
            titleAR: blog.DataAR.title,
            blogContentFR: blog.DataFR.blogContent,
            blogContentEN: blog.DataEN.blogContent,
            blogContentAR: blog.DataAR.blogContent,
            authorFR: blog.DataFR.author,
            authorEN: blog.DataEN.author,
            authorAR: blog.DataAR.author,
            locationFR: blog.DataFR.location,
            locationEN: blog.DataEN.location,
            locationAR: blog.DataAR.location,
            blogDate: blog.postDate,
          });

          // Set the cover image if available
          if (blog.coverSrc) {
            this.uploadedCover = blog.coverSrc;
          }
        }
      });
  }
}
