import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BlogService } from '../services/blog.service';
import { AlertsService } from '../../../shared/alerts-component/alerts.service'; 
import {
  getBlogListAction,
  blogListFetchedAction,
  addBlogAction,
  blogAddedAction,
  updateBlogAction,
  blogUpdatedAction,
  deleteBlogAction,
  blogDeletedAction,
  getSelectedBlogAction,
  blogDetailsFetchedAction,
} from './blog.actions';
import { catchError, EMPTY, map, mergeMap, finalize } from 'rxjs';
import { ErrorHandlerService } from '../../../core/services/error/errorHander.service';
import { SpinnerService } from '../../../shared/spinner/SpinnerService';

@Injectable()
export class BlogEffects {
  private action$ = inject(Actions);
  private blogService = inject(BlogService);
  private errorHandler = inject(ErrorHandlerService);
  private alertsService = inject(AlertsService); 
  private spinnerService = inject(SpinnerService);  

  loadBlogList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getBlogListAction),
      mergeMap(() => {
        return this.blogService.getBlogList().pipe(
          map((blogs) => blogListFetchedAction({ payload: blogs })),
          catchError((err) => {
            this.errorHandler.handleError('Liste des blogs', err);
            this.alertsService.showAnAlert({ text: 'Erreur lors du chargement des blogs.', style: 'warning', duration: 3000 });
            return EMPTY;
          }),
        );
      })
    )
  );

  loadBlogDetails$ = createEffect(() =>
    this.action$.pipe(
      ofType(getSelectedBlogAction),
      mergeMap((action) => {
        this.spinnerService.showSpinner(); 
        return this.blogService.getBlogDetails(action.payload).pipe(
          map((res) => blogDetailsFetchedAction({ payload: res })),
          catchError((err) => {
            this.errorHandler.handleError('Détails du blog', err);
            this.alertsService.showAnAlert({ text: 'Erreur lors du chargement des détails du blog.', style: 'warning', duration: 3000 });
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner()) 
        );
      })
    )
  );

  addBlog$ = createEffect(() =>
    this.action$.pipe(
      ofType(addBlogAction),
      mergeMap((action) => {
        this.spinnerService.showSpinner(); 
        return this.blogService.addBlogList(action.payload).pipe(
          map((newBlog) => {
            this.alertsService.showAnAlert({ text: 'Blog ajouté avec succès !', style: 'success', duration: 3000 });
            return blogAddedAction({ payload: newBlog });
          }),
          catchError((err) => {
            this.errorHandler.handleError('Ajouter un blog', err);
            this.alertsService.showAnAlert({ text: 'Erreur lors de l\'ajout du blog.', style: 'warning', duration: 3000 });
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner())  
        );
      })
    )
  );

  updateBlog$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateBlogAction),
      mergeMap((action) => {
        this.spinnerService.showSpinner();  
        return this.blogService.updateBlogList(action.payload).pipe(
          map((updatedBlog) => {
            this.alertsService.showAnAlert({ text: 'Blog mis à jour avec succès !', style: 'success', duration: 3000 });
            return blogUpdatedAction({ payload: updatedBlog });
          }),
          catchError((err) => {
            this.errorHandler.handleError('Mettre à jour un blog', err);
            this.alertsService.showAnAlert({ text: 'Erreur lors de la mise à jour du blog.', style: 'warning', duration: 3000 });
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner())  
        );
      })
    )
  );

  deleteBlog$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteBlogAction),
      mergeMap((action) => {
        this.spinnerService.showSpinner();  
        return this.blogService.deleteBlogList(action.payload).pipe(
          map(() => {
            this.alertsService.showAnAlert({ text: 'Blog supprimé avec succès !', style: 'success', duration: 3000 });
            return blogDeletedAction({ payload: action.payload });
          }),
          catchError((err) => {
            this.errorHandler.handleError('Supprimer un blog', err);
            this.alertsService.showAnAlert({ text: 'Erreur lors de la suppression du blog.', style: 'warning', duration: 3000 });
            return EMPTY;
          }),
          finalize(() => this.spinnerService.hideSpinner()) 
        );
      })
    )
  );
}
