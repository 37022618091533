import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AppState } from './core/models/store-typings.model';
import { productPageRootReducer } from './modules/products/store/product.reducer';
import { blogPageRootReducer } from './modules/blog/store/blog.reducer';
import { uploadPageRootReducer } from './shared/upload/store/upload.reducer';
import { loginPageRootReducer } from './modules/login/store/login.reducers';

export const reducers: ActionReducerMap<AppState> = {
  productPage: productPageRootReducer,
  blogPage: blogPageRootReducer,
  uploadPage: uploadPageRootReducer,
  loginPage: loginPageRootReducer,
};
export const metaReducers: MetaReducer<AppState>[] = [];
