<div class="row position-relative">
  <div class="col-12 col-md-3 text-center text-md-start headings">
    <h2 class="font-weight-bold mb-3">{{ heading | translate }}</h2>
    <p class="text-muted">{{ description | translate }}</p>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary"  (click)="navigateToProducts()">
        {{ "voir_plus" | translate }}
      </button>
    </div>
  </div>

  <div class="col-12 col-md-9 position-relative product-items-container px-0">
    <div class="d-flex justify-content-center justify-content-md-end">
      <div class="row g-4 mx-0 w-100">
        <div class="col-12 col-sm-6 col-md-4" *ngFor="let item of currentSlice">
          <app-product-carousel-item
            [product]="item"
          ></app-product-carousel-item>
        </div>
      </div>

      <div
        class="position-absolute button-container prev-button-container"
        *ngFor="let item of currentSlice; let isFirst = first"
      >
        <ng-container *ngIf="isFirst">
          <button
            class="btn btn-primary rounded-circle button-style btn-lg"
            (click)="onPrev()"
            [disabled]="currentNewProductIndex === 0"
          >
            <i class="bi bi-chevron-left"></i>
          </button>
        </ng-container>
      </div>

      <div
        class="position-absolute button-container next-button-container"
        *ngFor="let item of currentSlice; let isLast = last"
      >
        <ng-container *ngIf="isLast">
          <button
            class="btn btn-primary rounded-circle button-style btn-lg next-button-margin"
            (click)="onNext()"
            [disabled]="
              currentNewProductIndex + (isLargeScreen ? 3 : 1) >=
              itemsContent.length
            "
          >
            <i class="bi bi-chevron-right"></i>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
