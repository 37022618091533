import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { ProductAttributes } from '../../../../core/models/typings.models';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../../../../core/services/LanguageService/language.service';

@Component({
  selector: 'app-product-item',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent {
  @Input() product: ProductAttributes = {} as ProductAttributes;
  title: string = '';
  description: string = '';
  currentLanguage: string = ''; // Default language

  constructor(private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.currentLanguage = this.languageService.getTranslation().currentLang;
      this.updateLanguageDependentFields();

      this.languageService.getTranslation().onLangChange.subscribe((event) => {
        this.currentLanguage = event.lang;
        this.updateLanguageDependentFields();
      });
    }
  }

  updateLanguageDependentFields(): void {

    if (this.currentLanguage === 'ar') {
      this.title = this.product.lanAR.title;
      this.description = this.extractFirstParagraph(this.product.lanAR.description);
    } else if (this.currentLanguage === 'en') {
      this.title = this.product.lanEN.title;
      this.description = this.extractFirstParagraph(this.product.lanEN.description);
    } else if (this.currentLanguage === 'fr') {
      this.title = this.product.lanFR.title;
      this.description = this.extractFirstParagraph(this.product.lanFR.description);
    }
  }

  selectedProduct() {
    this.navigateToDetail();
  }
  navigateToDetail() {
    this.router.navigate(['/products', this.product.id]);
  }
  extractFirstParagraph(content: string, maxLength: number = 20): string {

    return content.length > maxLength
      ? content.slice(0, maxLength) + '...'
      : content;
  }
}
