import { inject, Injectable } from '@angular/core';
import { AlertsService } from '../../../shared/alerts-component/alerts.service';
import { LoginFacade } from '../service/login-facade.service';
import { ErrorHandlerService } from '../../../core/services/error/errorHander.service';
import { LoginService } from '../service/login.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../../../core/models/store-typings.model';
import { Store } from '@ngrx/store';
import {
  LogInFailureAction,
  logInSuccessAction,
  logOutAction,
  routeIfAlreadyLoggedAction,
  TryLoginUserAction,
} from './login.actions';
import {
  catchError,
  EMPTY,
  tap,
  withLatestFrom,
  exhaustMap,
  map,
  of,
  finalize,
} from 'rxjs';
import { Router } from '@angular/router';
import { loginQuery } from './login.selectors';
import { SpinnerService } from '../../../shared/spinner/SpinnerService';

@Injectable({ providedIn: 'root' })
export class LoginEffect {
  private action$ = inject(Actions);
  private loginService = inject(LoginService);
  private errorHandler = inject(ErrorHandlerService);
  private loginFacade = inject(LoginFacade);
  private alertService = inject(AlertsService);
  private router = inject(Router);
  private store = inject(Store<AppState>);
  private spinnerService = inject(SpinnerService);  

  loadTokenEffect$ = createEffect(() =>
    this.action$.pipe(
      ofType(TryLoginUserAction),
      exhaustMap((action) =>
        this.loginService.tryLogin(action.payload).pipe(
          map((token) => logInSuccessAction({ payload: token })),
          catchError((err) => {
            this.errorHandler.handleError('Erreur Identification', err);
            return of(LogInFailureAction({ payload: err }));
          })
        )
      )
    )
  );

  ifLoggedInRoute$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(routeIfAlreadyLoggedAction),
        withLatestFrom(this.store.select(loginQuery.userLogedIn)), // Use the selector here
        tap(([_, isUserLogged]) => {
          if (isUserLogged) {
            this.router.navigate(['/home']);
          }
        })
      ),
    { dispatch: false }
  );

  loginSuccessEffect$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(logInSuccessAction),
        tap(({ payload }) => {
          if (!payload.token) {
            return this.loginFacade.logOut();
          }
  
          this.spinnerService.showSpinner();
  
          this.loginService.checkAuthorization().subscribe(
            () => {
              localStorage.setItem('authToken', payload.token);
              this.alertService.showAnAlert({
                text: 'Bienvenue Admin ',
                duration: 2000,
                style: 'success',
              });
              this.router.navigate(['/addproduct']);
            },
            (err) => {
              this.errorHandler.handleError('Authorize', err);
              return this.loginFacade.logOut();
            },
            () => this.spinnerService.hideSpinner()
          );
        }),
        catchError((err) => {
          console.log('err', err);
          this.errorHandler.handleError('Erreur Identification', err);
          this.spinnerService.hideSpinner();
          return EMPTY;
        })
      ),
    { dispatch: false }
  );
  
  logOutEffect$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(logOutAction),
        tap(
          () => {
            this.spinnerService.showSpinner(); 

            localStorage.removeItem('authToken');
            this.router.navigate(['/login']);
            this.alertService.showAnAlert({
              text: 'Session terminée',
              duration: 2000,
              style: 'primary',
            });
  
          this.spinnerService.hideSpinner();
        }),
        catchError(() => {
          this.spinnerService.hideSpinner();
          return EMPTY;
        })
      ),
    { dispatch: false }
  );
  
}