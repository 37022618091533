<div class="card shadow-sm" (click)="selectedProduct()">
  <div class="card-header d-flex align-items-center p-2">
    <div class="badge-container d-flex justify-content-end flex-grow-1 gap-1">
      <span *ngIf="product.isSterile" class="badge rounded-pill sterile-badge">{{ "Sterile" | translate }}</span>
      <span *ngIf="!product.isSterile" class="badge rounded-pill non-sterile-badge">Non Sterile</span>
      <span *ngIf="product.bestSeller" class="badge rounded-pill best-seller-badge">
        <i class="bi bi-star-fill"></i> {{ "best-seller" | translate }}
      </span>
      <span *ngIf="product.isNew" class="badge rounded-pill new-badge">{{ "new" | translate }}</span>
    </div>
  </div>

  <img [src]="product.imgsrc[0]" class="card-img-top" alt="Product Image" />

  <div class="card-body text-center p-3 flex-grow-1">
    <h5 class="card-title fs-5 mb-2">{{ title }}</h5>
    <p class="card-text text-muted fs-6">{{ description }}</p>
  </div>
</div>