import { contactInfo } from '../../shared/contact-info';
import { AfterViewInit, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-subcontracting',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './subcontracting.component.html',
  styleUrl: './subcontracting.component.scss',
})
export class SubcontractingComponent implements AfterViewInit {
  contactInfo = contactInfo;

  ngAfterViewInit() {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animate');
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.5 }
      );
      document.querySelectorAll('.page-element').forEach((element) => {
        observer.observe(element);
      });
    }
  }
}
