import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/api/api.service';
import { Observable, tap } from 'rxjs';
import {
  ILoginCredentials,
  SignInResponse,
} from '../../../core/models/typings.models';
import { AuthService } from '../../../core/services/authService/auth.service';

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {}

  tryLogin({
    username,
    password,
  }: ILoginCredentials): Observable<SignInResponse> {
    return this.apiService
      .post<SignInResponse>('/signin', {
        username,
        password,
      })
      .pipe(
        tap((response: SignInResponse) => {
          if (response.token) {
            this.authService.setToken(response.token);
          }
        })
      );
  }

  checkAuthorization(): Observable<any> {
    return this.apiService.get('/authorize');
  }

  showDebugInfo() {
    return this.apiService.get('/debuginfo');
  }
}
