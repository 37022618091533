<div class="card h-100">
  <img [src]="product.image" class="card-img-top" [alt]="product.title" />
  <div class="card-body text-start">
    <h6 class="text-muted mt-3">
      {{ product.category | translate }}
    </h6>
    <h5 class="card-title font-weight-bold mt-3">
      {{ product.title | translate }}
    </h5>
  </div>
</div>
