import {
  Component,
  OnDestroy,
  OnInit,
  Inject,
  PLATFORM_ID,
  ElementRef,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BlogAttributes } from '../../../../core/models/typings.models';
import { Subject, takeUntil } from 'rxjs';
import { BlogFacade } from '../../services/blog-facade.service';
import { LanguageService } from '../../../../core/services/LanguageService/language.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-blog-details',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogDetailsComponent implements OnInit, OnDestroy {
  Blog: BlogAttributes = {} as BlogAttributes;
  destroy$ = new Subject<void>();
  currentLanguage: string = 'FR'; // Default language
  title: string = '';
  location: string = '';
  content: string = '';

  @ViewChild('BlogContent', { static: true }) blogContentElement!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private blogFacade: BlogFacade,
    @Inject(PLATFORM_ID) private platformId: Object,
    private languageService: LanguageService,
    private renderer: Renderer2,
   private router: Router
  ) {}

  ngOnInit(): void {
    const blogId = this.route.snapshot.paramMap.get('id');
    if (blogId) {
      this.blogFacade.loadSelectedBlog(blogId);

      this.blogFacade
        .getBlogDetail()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: BlogAttributes) => {
          this.Blog = data;
          this.updateLanguageDependentFields(); // Update fields for the first time
        });

      if (isPlatformBrowser(this.platformId)) {
        this.currentLanguage =
          this.languageService.getTranslation().currentLang;

        this.languageService
          .getTranslation()
          .onLangChange.subscribe((event) => {
            this.currentLanguage = event.lang;
            this.updateLanguageDependentFields(); // Update fields on language change
          });
      }
    }
  }

  updateLanguageDependentFields(): void {
    let blogData = null;

    if (this.currentLanguage === 'ar') {
      blogData = this.Blog.DataAR;
    } else if (this.currentLanguage === 'en') {
      blogData = this.Blog.DataEN;
    } else if (this.currentLanguage === 'fr') {
      blogData = this.Blog.DataFR;
    }

    if (blogData) {
      this.title = blogData.title.toUpperCase();
      this.location = blogData.location;
      this.renderBlogContent(blogData.blogContent);
    }
  }

  renderBlogContent(content: string): void {
    // Clear existing content
    this.renderer.setProperty(
      this.blogContentElement.nativeElement,
      'innerHTML',
      ''
    );

    const tempDiv = this.renderer.createElement('div');
    tempDiv.innerHTML = content;

    // Apply classes to elements
    const headers = tempDiv.querySelectorAll('h1');
    headers.forEach((header: HTMLElement) => {
      this.renderer.addClass(header, 'fw-bold');
      this.renderer.addClass(header, 'headers');
    });

    const images = tempDiv.querySelectorAll('img');
    images.forEach((img: HTMLImageElement) => {
      this.renderer.addClass(img, 'img-fluid');
      this.renderer.addClass(img, 'rounded');
      this.renderer.addClass(img, 'mb-3');
    });

    // Append updated content to the target element
    Array.from(tempDiv.childNodes).forEach((node) => {
      this.renderer.appendChild(this.blogContentElement.nativeElement, node);
    });
  }
  navigateToBlogs(): void {
    this.router.navigate(['/blog']);
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
