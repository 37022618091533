export const contactInfo = {
  phone: '036692222',
  phone_marketing: '0671297279',
  email_marketing: 'marketing@aldismed.com',
  phone_commercial_1: '0660839702',
  phone_commercial_2: '0657839720',
  email_commercial: 'commercial@aldismed.com',
  phone_technique_1: '0657839733',
  phone_technique_2: '0771703437',
  email_technique: 'direction.technique@aldismed.com',
};
