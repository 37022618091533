import { createSelector } from '@ngrx/store';
import {
  AppState,
  ILoginPageState,
} from '../../../core/models/store-typings.model';

const selectLoginQuery = (state: AppState): ILoginPageState => state.loginPage;

const userLogedIn = createSelector(
  selectLoginQuery,
  (user) => user.isAuthenticated
);
const getToken = createSelector(
  selectLoginQuery,
  (userLoged) => userLoged.token
);

export const loginQuery = {
  userLogedIn,
  getToken,
};
