import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { BlogListItemComponent } from './blog-list-item/blog-list-item.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BlogAttributes } from '../../../../core/models/typings.models';
import { BlogFacade } from '../../../blog/services/blog-facade.service';

@Component({
  selector: 'app-blog-list',
  standalone: true,
  imports: [SidebarComponent, BlogListItemComponent, NgxPaginationModule, CommonModule],
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  blogs: BlogAttributes[] = [];
  selectedCount = 0;
  itemsPerPage = 6;
  currentPage = 1;
  totalPages = 0;
  pages: number[] = [];

  constructor(private blogFacade: BlogFacade) { }

  ngOnInit(): void {
    this.loadBlogs();
  }

  private loadBlogs(): void {
    this.blogFacade.loadBlogList();
    this.fetchBlogs();
  }

  private fetchBlogs(): void {
    this.blogFacade
      .fetchBlogList()
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          console.error('Failed to fetch blog list:', error);
          return [];
        })
      )
      .subscribe((blogsList: BlogAttributes[]) => {
        this.blogs = blogsList;
        this.selectedCount = blogsList.length;
        this.totalPages = this.selectedCount > 0 ? Math.ceil(this.selectedCount / this.itemsPerPage) : 0;
        this.pages = Array.from({ length: this.totalPages }, (_, i) => i + 1);
      });
  }

  onPageChange(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      document.getElementById('blog-list')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
