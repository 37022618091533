import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ProductItemComponent } from './component/product-item/product-item.component';
import { ProductAttributes } from '../../core/models/typings.models';
import { ProductFacade } from './services/product-facade.service';
import { Subject, takeUntil } from 'rxjs';
import { NgxPaginationModule } from 'ngx-pagination';
import { LanguageService } from '../../core/services/LanguageService/language.service';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ProductItemComponent,
    NgxPaginationModule,
  ],
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductsComponent implements OnInit, OnDestroy {
  products: ProductAttributes[] = [];
  filteredProducts: ProductAttributes[] = [];
  filters: any[] = [];
  selectedCount = 0;
  itemsPerPage = 9;
  currentPage = 1;
  destroy$ = new Subject<void>();
  translate: any = null
  constructor(
    private productFacade: ProductFacade,
    private languageService: LanguageService
  ) {
    this.translate = this.languageService.getTranslation()
  }
  ngOnInit(): void {
    this.getProductList();

    // Reactively update filter labels on language change
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateFilterCounts();
    });
  }

  getProductList() {
    this.productFacade.loadProductList();
    this.fetchProducts();
  }

  fetchProducts() {
    this.productFacade
      .fetchProductList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((productList: ProductAttributes[]) => {
        this.products = productList;
        this.updateFilterCounts();
        this.onFilterChange();
      });
  }

  updateFilterCounts() {
    const selectedIds = this.filters.filter(f => f.selected).map(f => f.id);

    const bestSellerCount = this.products.filter((p) => p.bestSeller).length;
    const newCount = this.products.filter((p) => p.isNew).length;
    const sterileCount = this.products.filter((p) => p.isSterile).length;
    const nonSterileCount = this.products.filter((p) => !p.isSterile).length;

    const typeCounts = {
      tBandages: this.products.filter((p) => p.typeID === 'tBandages').length,
      tCompresses: this.products.filter((p) => p.typeID === 'tCompresses').length,
      tDressings: this.products.filter((p) => p.typeID === 'tDressings').length,
      tPlasters: this.products.filter((p) => p.typeID === 'tPlasters').length,
      tOthers: this.products.filter((p) => p.typeID === 'tOthers').length,
    };

    const updatedFilters = [
      { id: 'bestSeller', label: this.translate.instant('best-seller'), count: bestSellerCount },
      { id: 'new', label: this.translate.instant('new'), count: newCount },
      { id: 'sterile', label: this.translate.instant('Sterile'), count: sterileCount },
      { id: 'nonSterile', label: this.translate.instant('non-sterile'), count: nonSterileCount },
      { id: 'tBandages', label: this.translate.instant('Bandages'), count: typeCounts.tBandages },
      { id: 'tCompresses', label: this.translate.instant('Compresses'), count: typeCounts.tCompresses },
      { id: 'tDressings', label: this.translate.instant('dressings'), count: typeCounts.tDressings },
      { id: 'tPlasters', label: this.translate.instant('plasters'), count: typeCounts.tPlasters },
      { id: 'tOthers', label: this.translate.instant('others'), count: typeCounts.tOthers },
      { id: 'all', label: this.translate.instant('all'), count: this.products.length },
    ];

    this.filters = updatedFilters.map(filter => ({
      ...filter,
      selected: selectedIds.includes(filter.id),
    }));
  }


  onFilterChange() {
    this.currentPage = 1;
    const selectedIds = this.filters
      .filter((f) => f.selected)
      .map((f) => f.id);

    if (selectedIds.includes('all') || selectedIds.length === 0) {
      this.filteredProducts = [...this.products];
    } else {
      this.filteredProducts = this.products.filter((product) => {
        let matches = false;

        if (selectedIds.includes('new') && product.isNew) matches = true;
        if (selectedIds.includes('bestSeller') && product.bestSeller) matches = true;
        if (selectedIds.includes('sterile') && product.isSterile) matches = true;
        if (selectedIds.includes('nonSterile') && !product.isSterile) matches = true;

        if (selectedIds.includes(product.typeID)) matches = true;

        return matches;
      });
    }

    this.selectedCount = this.filteredProducts.length;
  }


  onPageChange(page: number): void {
    this.currentPage = page;

    const element = document.getElementById('blog-list');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}