<div class="d-flex vh-100 force-ltr">
  <app-sidebar></app-sidebar>

  <div class="flex-grow-1 p-4 main-content">
    <div class="card p-4">
      <h2 class="mb-4">Add Product</h2>
      <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="french-tab"
            data-bs-toggle="tab"
            href="#french"
            role="tab"
            aria-controls="french"
            aria-selected="true"
            >Français</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="english-tab"
            data-bs-toggle="tab"
            href="#english"
            role="tab"
            aria-controls="english"
            aria-selected="false"
            >Anglais</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="arabic-tab"
            data-bs-toggle="tab"
            href="#arabic"
            role="tab"
            aria-controls="arabic"
            aria-selected="false"
            >Arab</a
          >
        </li>
      </ul>

      <form [formGroup]="productForm" novalidate class="needs-validation">
        <div class="row mb-3 d-flex align-items-start">
          <div class="col-12 col-md-4 d-flex align-items-center mb-3 mb-md-0">
            <div class="row w-100 m-0">
              <div class="row mb-2">
                <label
                  for="ficheTechnique"
                  class="col-auto form-label me-2 flex-shrink-0 mt-2"
                  >Fiche Technique</label
                >
                <input
                  type="file"
                  class="col form-control flex-grow-1"
                  id="ficheTechnique"
                  accept=".pdf,.doc,.docx"
                  (change)="onFileSelect($event, 'technicalSheet')"
                  [ngClass]="getValidationClass('technicalSheet')"
                />
                <div
                  *ngIf="isInvalid('technicalSheet')"
                  class="invalid-feedback"
                >
                  Technical Sheet is required.
                </div>
              </div>
              <div *ngIf="uploadedFile !== ''" class="col-12 preview-container">
                <div class="file-preview">
                  <span><strong>Fiche Technique: </strong></span>
                  <span>{{ uploadedFileName }}</span>
                  <button
                    type="button"
                    class="delete-btn"
                    (click)="
                      deleteUploadedItem(uploadedFileName, 'technicalSheet')
                    "
                    aria-label="Delete File"
                  >
                    <i class="bi bi-x-circle-fill"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 d-flex align-items-center">
            <div class="row w-100 m-0">
              <div class="row mb-2">
                <label
                  for="productPhotos"
                  class="col-auto form-label me-2 flex-shrink-0 mt-2"
                  >Product Photos</label
                >
                <input
                  type="file"
                  class="col form-control flex-grow-1"
                  id="productPhotos"
                  accept="image/*"
                  (change)="onFileSelect($event, 'photos')"
                  [ngClass]="getValidationClass('photos')"
                />
                <div *ngIf="isInvalid('photos')" class="invalid-feedback">
                  photo is required.
                </div>
              </div>
              <div
                *ngIf="uploadedImages[0] !== ''"
                class="col-12 preview-container"
              >
                <div
                  *ngFor="let imageUrl of uploadedImages"
                  class="preview-item"
                >
                  <img [src]="imageUrl" alt="Preview" class="preview-image" />
                  <button
                    type="button"
                    class="delete-btn delete-btn-image"
                    (click)="deleteUploadedItem(imageUrl, 'photos')"
                    aria-label="Delete Image"
                  >
                    <i class="bi bi-x-circle-fill"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-md-4">
            <label for="category2" class="form-label">Famille</label>
            <select
              class="form-select"
              id="category2"
              formControlName="category"
              [ngClass]="getValidationClass('category')"
            >
            <option value="Bandages">Bandages</option>
            <option value="Sparadraps">Sparadraps</option>
            <option value="Compresses">Compresses</option>
            <option value="Pansements">Pansements</option>
            <option value="Autres">Autres</option>
            
            </select>
            <div *ngIf="isInvalid('category')" class="invalid-feedback">
              Category is required.
            </div>
          </div>

          <div class="col-12 col-md-4 mb-3 mb-md-0">
            <label for="title" class="form-label">Reference</label>
            <input
              required
              type="text"
              class="form-control"
              id="reference"
              placeholder="Entrer un reference"
              formControlName="reference"
              [ngClass]="getValidationClass('reference')"
            />
            <div *ngIf="isInvalid('reference')" class="invalid-feedback">
              "Reference" is required.
            </div>
          </div>

          <div class="col-12 col-md-4 d-flex align-items-center mb-3 mb-md-0">
            <div class="form-check me-3">
              <input
                type="checkbox"
                class="form-check-input"
                id="bestSeller"
                formControlName="isBestSeller"
              />
              <label class="form-check-label" for="bestSeller"
                >Best Seller</label
              >
            </div>
            <div class="form-check me-3">
              <input
                type="checkbox"
                class="form-check-input"
                id="new"
                formControlName="isNew"
              />
              <label class="form-check-label" for="new">New</label>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="sterile"
                formControlName="isSterile"
              />
              <label class="form-check-label" for="sterile">Sterile</label>
            </div>
          </div>
        </div>

        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="french"
            role="tabpanel"
            aria-labelledby="french-tab"
          >
            <div class="row mb-3 align-items-center">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <label for="title" class="form-label">Titre</label>
                <input
                  type="text"
                  class="form-control"
                  id="title-fr"
                  placeholder="Entrer un titre"
                  formControlName="titleFR"
                  [ngClass]="getValidationClass('titleFR')"
                />
                <div *ngIf="isInvalid('titleFR')" class="invalid-feedback">
                  Titre est obligatoire.
                </div>
              </div>

              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <label for="title" class="form-label">Description</label>
                <textarea
                  type="text"
                  class="form-control"
                  id="ddescription-fr"
                  placeholder="Entrer un Description"
                  formControlName="descriptionFR"
                  [ngClass]="getValidationClass('descriptionFR')"
                ></textarea>
                <div
                  *ngIf="isInvalid('descriptionFR')"
                  class="invalid-feedback"
                >
                  La Description est Obligatoire.
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label
                for="editor-desc-fr"
                class="form-label-title font-weight-bold mb-4"
                >Description générale</label
              >
              <editor
                id="editor-desc-fr"
                [init]="editorInit"
                formControlName="descriptionGeneraleFR"
                name="descriptionGenerale"
                [ngClass]="getValidationClass('descriptionGeneraleFR')"
              ></editor>
              <div
                *ngIf="isInvalid('descriptionGeneraleFR')"
                class="invalid-feedback"
              >
                La Description Générale est Obligatoire.
              </div>
            </div>

            <div class="mb-3">
              <label
                for="editor-tech-fr"
                class="form-label-title font-weight-bold mb-4"
                >Informations techniques</label
              >
              <editor
                id="editor-tech-fr"
                [init]="editorInit"
                formControlName="informationsTechniquesFR"
                name="informationsTechniques"
                [ngClass]="getValidationClass('descriptionGeneraleFR')"
              ></editor>
              <div
                *ngIf="isInvalid('informationsTechniquesFR')"
                class="invalid-feedback"
              >
                Les informations techniques sont obligatoires.
              </div>
            </div>

            <div class="mb-3">
              <label
                for="editor-gen-fr"
                class="form-label-title font-weight-bold mb-4"
                >Informations générales</label
              >
              <editor
                id="editor-gen-fr"
                [init]="editorInit"
                name="informationsGenerales"
                formControlName="informationsGeneralesFR"
                [ngClass]="getValidationClass('informationsGeneralesFR')"
              ></editor>
              <div
                *ngIf="isInvalid('informationsGeneralesFR')"
                class="invalid-feedback"
              >
                Les Informations Générales Sont Obligatoires
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="english"
            role="tabpanel"
            aria-labelledby="english-tab"
          >
            <div class="row mb-3 align-items-center">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <label for="title" class="form-label">Title</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  id="title-en"
                  placeholder="Entrer un titre"
                  formControlName="titleEN"
                  [ngClass]="getValidationClass('titleEN')"
                />

                <div *ngIf="isInvalid('titleEN')" class="invalid-feedback">
                  Title is required.
                </div>
              </div>

              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <label for="title" class="form-label">Description</label>
                <textarea
                  type="text"
                  class="form-control"
                  id="description-en"
                  placeholder="Entrer un Description"
                  formControlName="descriptionEN"
                  [ngClass]="getValidationClass('descriptionEN')"
                ></textarea>
                <div
                  *ngIf="isInvalid('descriptionEN')"
                  class="invalid-feedback"
                >
                  Description is required.
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label
                for="editor-desc-en"
                class="form-label-title font-weight-bold mb-4"
                >General description</label
              >
              <editor
                id="editor-desc-en"
                [init]="editorInit"
                formControlName="descriptionGeneraleEN"
                name="descriptionGeneraleEN"
                [ngClass]="getValidationClass('descriptionGeneraleEN')"
              ></editor>
              <div
                *ngIf="isInvalid('descriptionGeneraleEN')"
                class="invalid-feedback"
              >
                General Description Is Required.
              </div>
            </div>

            <div class="mb-3">
              <label
                for="editor-tech-en"
                class="form-label-title font-weight-bold mb-4"
                >Technical information</label
              >
              <editor
                id="editor-tech-en"
                [init]="editorInit"
                formControlName="informationsTechniquesEN"
                name="informationsTechniques"
                [ngClass]="getValidationClass('informationsTechniquesEN')"
              ></editor>
              <div
                *ngIf="isInvalid('informationsTechniquesEN')"
                class="invalid-feedback"
              >
                Technical Information Is Required.
              </div>
            </div>

            <div class="mb-3">
              <label
                for="editor-gen-en"
                class="form-label-title font-weight-bold mb-4"
                >General information</label
              >
              <editor
                id="editor-gen-en"
                [init]="editorInit"
                formControlName="informationsGeneralesEN"
                name="informationsGeneralesEN"
                [ngClass]="getValidationClass('informationsGeneralesEN')"
              ></editor>
              <div
                *ngIf="isInvalid('informationsGeneralesEN')"
                class="invalid-feedback"
              >
                General Information Is Required.
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="arabic"
            role="tabpanel"
            aria-labelledby="arabic-tab"
            style="direction: rtl"
          >
            <div class="row mb-3 align-items-center">
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <label for="title" class="form-label">العنوان</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  id="title-ar"
                  placeholder="Entrer un titre"
                  formControlName="titleAR"
                  [ngClass]="getValidationClass('titleAR')"
                />
                <div *ngIf="isInvalid('titleAR')" class="invalid-feedback">
                  العنوان مطلوب.
                </div>
              </div>
              <div class="col-12 col-md-4 mb-3 mb-md-0">
                <label for="title" class="form-label">الوصف</label>
                <textarea
                  type="text"
                  class="form-control"
                  id="description-ar"
                  placeholder="Entrer un Description"
                  formControlName="descriptionAR"
                  [ngClass]="getValidationClass('descriptionAR')"
                ></textarea>
                <div
                  *ngIf="isInvalid('descriptionAR')"
                  class="invalid-feedback"
                >
                  الوصف مطلوب.
                </div>
              </div>
            </div>

            <div class="mb-3">
              <label
                for="editor-desc-ar"
                class="form-label-title font-weight-bold mb-4"
                >الوصف العام</label
              >
              <editor
                id="editor-desc-ar"
                [init]="editorInit"
                formControlName="descriptionGeneraleAR"
                name="descriptionGeneraleAR"
                [ngClass]="getValidationClass('descriptionGeneraleAR')"
              ></editor>
              <div
                *ngIf="isInvalid('descriptionGeneraleAR')"
                class="invalid-feedback"
              >
                الوصف العام مطلوب.
              </div>
            </div>

            <div class="mb-3">
              <label
                for="editor-tech-ar"
                class="form-label-title font-weight-bold mb-4"
                >المعلومات الفنية</label
              >
              <editor
                id="editor-tech-ar"
                [init]="editorInit"
                formControlName="informationsTechniquesAR"
                name="informationsTechniquesAR"
                [ngClass]="getValidationClass('informationsTechniquesAR')"
              ></editor>
              <div
                *ngIf="isInvalid('informationsTechniquesAR')"
                class="invalid-feedback"
              >
                المعلومات الفنية مطلوبة.
              </div>
            </div>

            <div class="mb-3">
              <label
                for="editor-gen-ar"
                class="form-label-title font-weight-bold mb-4"
                >معلومات عامة</label
              >
              <editor
                id="editor-gen-ar"
                [init]="editorInit"
                formControlName="informationsGeneralesAR"
                name="informationsGeneralesAR"
                [ngClass]="getValidationClass('informationsGeneralesAR')"
              ></editor>
              <div
                *ngIf="isInvalid('informationsGeneralesAR')"
                class="invalid-feedback"
              >
                المعلومات العامة مطلوبة.
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-danger me-2"
            (click)="cancelRequest()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            (click)="submitForm($event)"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
