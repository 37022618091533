import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductFacade } from '../../services/product-facade.service';
import { ProductAttributes } from '../../../../core/models/typings.models';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { LanguageService } from '../../../../core/services/LanguageService/language.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-product-details',
  standalone: true,
  imports: [TranslateModule,CommonModule],
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {

  product: ProductAttributes = {} as ProductAttributes;
  destroy$ = new Subject<void>();
  currentLanguage: string = '';
  currentImageIndex: number = 0;
  title: string = '';
  description: string = '';
  type: string = '';

  @ViewChild('productImage', { static: false }) productImage!: ElementRef<HTMLImageElement>;
  @ViewChild('additionalInfo', { static: true }) additionalInfoElement!: ElementRef;
  @ViewChild('generalInfo', { static: true }) generalInfoElement!: ElementRef;
  @ViewChild('dimDic', { static: true }) descriptionElement!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
    private productFacade: ProductFacade,
    private languageService: LanguageService,
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngOnInit(): void {
    const productId = this.route.snapshot.paramMap.get('id');
    if (productId) {
      this.productFacade.loadSelectedProduct(productId);
      this.productFacade
        .getProductDetail()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: ProductAttributes) => {
          this.product = data;
          if (isPlatformBrowser(this.platformId)) {
            this.currentLanguage = this.languageService.getTranslation().currentLang;
            this.updateLanguageDependentFields();

            this.languageService.getTranslation().onLangChange.subscribe((event) => {
              this.currentLanguage = event.lang;
              this.updateLanguageDependentFields();
            });
          }
        });
    }

  }

  updateLanguageDependentFields(): void {

    if (this.currentLanguage === 'ar') {
      this.title = this.product.lanAR.title;
      this.description = this.product.lanAR.description;
      this.type = this.product.lanAR.type;
      this.renderBlogContent(this.additionalInfoElement, this.product.lanAR.tecInfo)
      this.renderBlogContent(this.generalInfoElement, this.product.lanAR.gnrlInfo)
      this.renderBlogContent(this.descriptionElement, this.product.lanAR.des_dim)
    } else if (this.currentLanguage === 'en') {
      this.title = this.product.lanEN.title;
      this.description = this.product.lanEN.description;
      this.type = this.product.lanEN.type;
      this.renderBlogContent(this.additionalInfoElement, this.product.lanEN.tecInfo)
      this.renderBlogContent(this.generalInfoElement, this.product.lanEN.gnrlInfo)
      this.renderBlogContent(this.descriptionElement, this.product.lanEN.des_dim)
    } else if (this.currentLanguage === 'fr') {
      this.title = this.product.lanFR.title;
      this.description = this.product.lanFR.description;
      this.type = this.product.lanFR.type;
      this.renderBlogContent(this.additionalInfoElement, this.product.lanFR.tecInfo)
      this.renderBlogContent(this.generalInfoElement, this.product.lanFR.gnrlInfo)
      this.renderBlogContent(this.descriptionElement, this.product.lanFR.des_dim)
    }
  }


  get currentImage(): string {
    return this.product.imgsrc[this.currentImageIndex];
  }

  ngAfterViewInit(): void {
    const img = this.productImage.nativeElement;

    img.addEventListener('mousemove', (event) => this.zoom(event, img));
    img.addEventListener('mouseleave', () => this.resetZoom(img));
  }

  zoom(event: MouseEvent, img: HTMLImageElement): void {
    const rect = img.getBoundingClientRect();

    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;

    const xPercent = (offsetX / rect.width) * 100;
    const yPercent = (offsetY / rect.height) * 100;

    img.style.transformOrigin = `${xPercent}% ${yPercent}%`;
    img.style.transform = 'scale(2)';
  }

  resetZoom(img: HTMLImageElement): void {
    img.style.transform = 'scale(1)';
    img.style.transformOrigin = 'center';
  }

  prevImage(): void {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
    }
  }

  nextImage(): void {
    if (this.currentImageIndex < this.product.imgsrc.length - 1) {
      this.currentImageIndex++;
    }
  }

  isPrevDisabled(): boolean {
    return this.currentImageIndex === 0;
  }

  isNextDisabled(): boolean {
    return this.currentImageIndex === this.product.imgsrc.length - 1;
  }

  renderBlogContent(element: ElementRef, content: string): void {
    // Clear existing content
    this.renderer.setProperty(
      element.nativeElement,
      'innerHTML',
      ''
    );

    const tempDiv = this.renderer.createElement('div');
    tempDiv.innerHTML = content;

    // Apply classes to elements
    const headers = tempDiv.querySelectorAll('h1');
    headers.forEach((header: HTMLElement) => {
      this.renderer.addClass(header, 'fw-bold');
      this.renderer.addClass(header, 'headers');
    });

    const images = tempDiv.querySelectorAll('img');
    images.forEach((img: HTMLImageElement) => {
      this.renderer.addClass(img, 'img-fluid');
      this.renderer.addClass(img, 'rounded');
      this.renderer.addClass(img, 'mb-3');
    });

    // Append updated content to the target element
    Array.from(tempDiv.childNodes).forEach((node) => {
      this.renderer.appendChild(element.nativeElement, node);
    });
  }
  navigateToProducts(): void {
    this.router.navigate(['/products']);
  }

  // ngOnDestroy(): void {
  //   this.destroy$.next();
  //   this.destroy$.complete();
  // }

}
