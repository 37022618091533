<div class="list-group-item d-flex justify-content-between align-items-center">
    <div>
        <h5 class="mb-0">{{ blog.DataFR.title }}</h5>
        <small class="text-muted">
            <span class="me-4">{{ blog.postDate }}</span>
            <i class="bi bi-geo-alt-fill"></i> {{ blog.DataFR.location }}
        </small>
    </div>
    <div class="btn-group">
        <button class="btn btn-bg me-2" style="background-color: #008080; color: white; border: 0px;"
            (click)="navigateToEditBlog()">
            Modifier <i class="bi bi-pencil-square"></i>
        </button>
        <button class="btn btn-danger btn-bg" (click)="deleteBlog()">
            Supprimer <i class="bi bi-trash"></i>
        </button>
    </div>
</div>